import { useState, ChangeEventHandler } from "react";
import { Box } from "@twilio-paste/core/box";
import { TextArea } from "@twilio-paste/core/textarea";
import { Button } from "@twilio-paste/core";

import { fieldStyles } from "./styles/PreEngagementFormPhase.styles";
import { useTranslation } from "../hooks/useTranslation";
import { HomeWrapper } from "./Home";

export const NewMessageComponent = ({
    handleSubmit,
    onChange
}: {
    handleSubmit: (e: string) => void | Promise<void>;
    onChange?: ChangeEventHandler<HTMLTextAreaElement>;
}) => {
    const { i18n } = useTranslation();
    const [query, setQuery] = useState("");

    const onSubmit: React.FormEventHandler<HTMLElement> = (e) => {
        e.preventDefault();
        handleSubmit(query);
    };

    const handleKeyPress = (e: React.KeyboardEvent<HTMLTextAreaElement>) => {
        if (e.key === "Enter" && !e.shiftKey) {
            e.preventDefault();
            onSubmit(e);
        }
    };

    return (
        <HomeWrapper onSubmit={onSubmit}>
            <Box {...fieldStyles}>
                <TextArea
                    placeholder={i18n.engagementFormLabelInputMessage}
                    name="query"
                    rows={2}
                    data-test="pre-engagement-chat-form-query-textarea"
                    value={query}
                    onChange={(e) => {
                        setQuery(e.target.value);
                        if (onChange) onChange(e);
                    }}
                    onKeyPress={handleKeyPress}
                    required
                />
            </Box>
            <Box paddingX="space100" display="flex" justifyContent="flex-end">
                <Button variant="primary" type="submit" data-test="pre-engagement-start-chat-button">
                    {i18n.engagementFormButtonSend}
                </Button>
            </Box>
        </HomeWrapper>
    );
};
