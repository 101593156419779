import { Box, Button } from "@twilio-paste/core";
import { CSSProperties, useMemo } from "react";
import { CloseIcon } from "@twilio-paste/icons/esm/CloseIcon";
import { useDispatch } from "react-redux";

import { ProactiveSupportMessage as ProactiveSupportMessageType } from "../modules/proactiveSupport/proactiveSupport.entities";
import { animationOpacityShow, animationOpacityY } from "./styles/Animations.styles";
import ProactiveSupportAvatars from "./ProactiveSupportAvatars";
import { deleteByIdProactiveSupportMessages } from "../store/actions/genericActions";
import { GetContentMessageProactiveSupportUseCase } from "../modules/proactiveSupport/useCases/getContentMessageProactiveSupport.useCase";

type ProactiveSupportMessageProps = {
    message: ProactiveSupportMessageType;
    isPrincipal: boolean;
    openMessages: boolean;
    numItem: number;
    onClick: (textVersionId: string) => void;
};

export const ProactiveSupportMessage = ({
    message,
    isPrincipal,
    openMessages,
    numItem,
    onClick
}: ProactiveSupportMessageProps): JSX.Element => {
    const timeForShow = (50 * 4) / numItem;
    const dispatch = useDispatch();

    const customStyles: CSSProperties =
        openMessages && !isPrincipal
            ? {
                  transition: `transform 300ms cubic-bezier(0.165, 0.84, 0.44, 1) ${timeForShow}ms`,
                  transform: `translate3d(0px, -120px, 0px)`,
                  position: "initial"
              }
            : {
                  transition: "transform 300ms cubic-bezier(0.165, 0.84, 0.44, 1) 0s",
                  transform: `translate3d(0px, 0px, 0px)`,
                  position: "absolute"
              };

    const stylesProactiveSupportMessageCard: {
        container: React.CSSProperties;
        card: React.CSSProperties;
        content: React.CSSProperties;
        containerAvatars: React.CSSProperties;
        containerBody: React.CSSProperties;
    } = {
        container: {
            position: "relative"
        },
        card: {
            display: "block",
            width: "70vw",
            height: "120px",
            maxWidth: "340px",
            borderRadius: "10px",
            padding: "20px",
            boxSizing: "border-box",
            background: "hsl(0deg 0% 96.78%)",
            boxShadow: "0 1px 6px rgba(0,0,0,.06),0 2px 32px rgba(0,0,0,.16)",
            backfaceVisibility: "hidden",
            transformOrigin: "center top",
            bottom: "0px",
            right: "0px",
            ...customStyles
        },
        content: {
            margin: 0,
            marginBottom: "4px",
            textTransform: "capitalize",
            fontWeight: "300",
            color: "gray",
            fontSize: ".9em"
        },
        containerAvatars: {
            cursor: "pointer",
            width: "100%",
            gap: "8px"
        },
        containerBody: {
            width: "100%",
            height: "80%",
            cursor: "pointer",
            paddingTop: "8px"
        }
    };

    const content = useMemo(() => {
        const getContentMessageProactiveSupportUseCase = new GetContentMessageProactiveSupportUseCase();
        return getContentMessageProactiveSupportUseCase.execute({
            textVersions: message.textVersions,
            messageId: message.uuid
        });
    }, [message.textVersions, message.uuid]);

    const linesToShow = 2;
    if (!content) return <></>;

    return (
        <Box
            animation={`600ms ease 0s 1 normal none running ${animationOpacityY}`}
            style={stylesProactiveSupportMessageCard.container}
            className="proactive-support-message-card"
        >
            <Box
                animation={`250ms ease 0s 1 normal none running ${animationOpacityShow}`}
                type="button"
                style={stylesProactiveSupportMessageCard.card}
            >
                <Box
                    display="flex"
                    justifyContent="space-between"
                    style={{
                        gap: "4px"
                    }}
                >
                    <Box
                        display="flex"
                        alignItems="end"
                        onClick={() => onClick(content.uuid)}
                        style={stylesProactiveSupportMessageCard.containerAvatars}
                    >
                        <ProactiveSupportAvatars />
                        <p style={stylesProactiveSupportMessageCard.content}>{message.brand.toLowerCase()}</p>
                    </Box>
                    <Box background="none">
                        <Button
                            variant="reset"
                            size="reset"
                            onClick={() => {
                                dispatch(deleteByIdProactiveSupportMessages(message.uuid));
                            }}
                        >
                            <CloseIcon decorative color="colorTextIconInverse" />
                        </Button>
                    </Box>
                </Box>

                <Box
                    animation={`500ms ease 0s 1 normal none running ${animationOpacityShow}`}
                    onClick={() => onClick(content.uuid)}
                    style={stylesProactiveSupportMessageCard.containerBody}
                >
                    <p
                        style={{
                            margin: 0,
                            padding: 0,
                            display: "-webkit-box",
                            WebkitLineClamp: linesToShow,
                            WebkitBoxOrient: "vertical",
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                            height: `${1.3 * linesToShow}em`
                        }}
                    >
                        {content.text}
                    </p>
                </Box>
            </Box>
        </Box>
    );
};
