import { useCallback, useEffect, useState } from "react";

const dispatchStorageEvent = (key: string, newValue: string | null | undefined): void => {
    window.dispatchEvent(new StorageEvent("storage", { key, newValue }));
};

const setLocalStorageItem = (key: string, value: unknown): void => {
    const stringifiedValue = JSON.stringify(value);
    window.localStorage.setItem(key, stringifiedValue);
    dispatchStorageEvent(key, stringifiedValue);
};

const removeLocalStorageItem = (key: string): void => {
    window.localStorage.removeItem(key);
    dispatchStorageEvent(key, null);
};

const getLocalStorageItem = (key: string): string | null => {
    return window.localStorage.getItem(key);
};

const localStorageSubscribe = (callback: (e: StorageEvent) => void): (() => void) => {
    window.addEventListener("storage", callback);
    return () => window.removeEventListener("storage", callback);
};

export function useLocalStorage<T>(key: string, initialValue: T): [T, (v: T) => void] {
    const [store, setStore] = useState<T>(() => {
        const item = getLocalStorageItem(key);
        return item ? JSON.parse(item) : initialValue;
    });

    const handleStorageChange = useCallback(
        (e: StorageEvent) => {
            if (e.key === key) {
                setStore(e.newValue ? JSON.parse(e.newValue) : initialValue);
            }
        },
        [key, initialValue]
    );

    useEffect(() => {
        const unsubscribe = localStorageSubscribe(handleStorageChange);
        return () => unsubscribe();
    }, [handleStorageChange]);

    const setState = useCallback(
        (v: T) => {
            try {
                const nextState = typeof v === "function" ? v(store) : v;

                if (nextState === undefined || nextState === null) {
                    removeLocalStorageItem(key);
                } else {
                    setLocalStorageItem(key, nextState);
                }

                setStore(nextState);
            } catch (e) {}
        },
        [key, store]
    );

    useEffect(() => {
        if (getLocalStorageItem(key) === null && typeof initialValue !== "undefined") {
            setLocalStorageItem(key, initialValue);
        }
    }, [key, initialValue]);

    return [store, setState];
}
