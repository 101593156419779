import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Dispatch } from "redux";

import { getChatHistoryRepo } from "../modules/chatHistory/chatHistory.repository";
import { AppState } from "../store/definitions";
import { ChatActions } from "../store/chat.reducer";

export const useChatHistory = () => {
    const dispatch = useDispatch<Dispatch<ChatActions>>();
    const conversationState = useSelector((state: AppState) => state.chat.conversationState);
    const conversationsClient = useSelector((state: AppState) => state.chat.conversationsClient);

    useEffect(() => {
        if (dispatch && conversationsClient?.user.identity)
            getChatHistoryRepo(conversationsClient?.user.identity, conversationsClient).then((data) => {
                dispatch({ type: "ACTION_ADD_CLOSED_CONVERSATIONS", payload: data });
            });
    }, [conversationState, dispatch, conversationsClient?.user.identity, conversationsClient]);
};
