import { useEffect, useRef } from "react";
import { styled } from "styled-components";

type Props = {
    open: boolean;
    children: JSX.Element | JSX.Element;
    onClose?: VoidFunction;
    position?: "left" | "right" | "top" | "bottom";
};

const DrawerContainer = styled.div`
    &.drawer-container {
        .drawer {
            z-index: 1000;
            height: 100%;
            overflow: auto;
            position: fixed;
            background-color: white;
            transition: transform 0.2s ease;
            &.left {
                top: 0;
                left: 0;
                transform: translateX(-105%);
            }
            &.right {
                top: 0;
                right: 0;
                width: 100%;
                transform: translateX(100%);
            }
            &.top {
                top: 0;
                left: 0;
                right: 0;
                width: 100%;
                height: 40%;
                transform: translateY(-100%);
            }
            &.bottom {
                bottom: 0;
                left: 0;
                right: 0;
                width: 100%;
                height: 40%;
                transform: translateY(100%);
            }
            &.in.open .top,
            &.in.open .bottom {
                transform: translateY(0);
            }
        }
        &.open {
            .left,
            .right {
                transform: translateX(0);
            }
            .top,
            .bottom {
                transform: translateY(0);
            }
        }
        .backdrop {
            visibility: hidden;
            opacity: 0;
            width: 100%;
            height: 100%;
            top: 0;
            left: 0;
            position: fixed;
            pointer-events: none;
            z-index: 0;
            transition: opacity 0.1s ease;
        }
        &.open .backdrop {
            visibility: visible;
            opacity: 1;
            pointer-events: auto;
            z-index: 999;
        }
        .backdrop-hide {
            background: transparent;
        }
    }
`;
const Drawer = ({ open, children, onClose, position = "right" }: Props): JSX.Element => {
    const drawerRef = useRef<HTMLDivElement>(null);
    // Prevent page scrolling when the drawer is open
    useEffect(() => {
        if (open) {
            document.body.style.overflow = "hidden";
        } else {
            document.body.style.overflow = "";
        }

        return (): void => {
            document.body.style.overflow = "";
        };
    }, [open]);

    // Allow Escape key to dismiss the drawer
    useEffect(() => {
        const onKeyPress = (e: KeyboardEvent): void => {
            if (e.key === "Escape" && onClose) {
                onClose();
            }
        };

        if (open) {
            window.addEventListener("keyup", onKeyPress);
        }

        return (): void => {
            window.removeEventListener("keyup", onKeyPress);
        };
    }, [open, onClose]);

    return (
        <DrawerContainer aria-hidden={open ? "false" : "true"} className={`drawer-container ${open ? "open" : ""}`}>
            <div ref={drawerRef} className={`drawer ${position}`} role="dialog">
                {children}
            </div>
        </DrawerContainer>
    );
};

export default Drawer;
