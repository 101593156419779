export const Mappa = () => {
    return (
        <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect width="30" height="30" rx="15" fill="black" />
            <path
                d="M8.52561 13.134V18.2402H7.60279V14.6284L6.15458 18.1736H5.37103L3.92282 14.5562V18.2402H3V13.134H4.30327L5.79564 16.774L7.28122 13.134H8.52561Z"
                fill="white"
            />
            <path
                d="M12.4262 16.372L11.6336 14.2354L10.841 16.372H12.4262ZM12.7037 17.1384H10.5715L10.1617 18.24H9.1958L11.1864 13.1338H12.116L14.1202 18.24H13.1181L12.7037 17.1384Z"
                fill="white"
            />
            <path
                d="M16.8535 15.5921C17.3358 15.5921 17.6461 15.2535 17.6461 14.7388C17.6461 14.2422 17.3313 13.9138 16.8535 13.9138H15.7212V15.5921H16.8535ZM14.7825 13.1406H16.8535C17.9145 13.1406 18.6244 13.7829 18.6244 14.7388C18.6244 15.7163 17.9145 16.3653 16.8535 16.3653H15.7212V18.24H14.7769L14.7825 13.1406Z"
                fill="white"
            />
            <path
                d="M21.4686 15.5921C21.951 15.5921 22.2612 15.2535 22.2612 14.7388C22.2612 14.2422 21.9465 13.9138 21.4686 13.9138H20.3363V15.5921H21.4686ZM19.4033 13.1406H21.4743C22.5353 13.1406 23.2452 13.7829 23.2452 14.7388C23.2452 15.7163 22.5353 16.3653 21.4743 16.3653H20.342V18.24H19.4033V13.1406Z"
                fill="white"
            />
            <path
                d="M26.3108 16.372L25.5182 14.2354L24.7256 16.372H26.3108ZM26.5882 17.1384H24.447L24.0371 18.24H23.0713L25.0653 13.1338H25.9949L28.0002 18.24H26.9902L26.5882 17.1384Z"
                fill="white"
            />
            <path d="M9.4461 12.2141H8.52441V13.1329H9.4461V12.2141Z" fill="white" />
        </svg>
    );
};
