import { Reducer } from "redux";
import { Conversation, Media, Message, Participant, User } from "@twilio/conversations";

import { ActionPayloadType, ChatState } from "./definitions";
import {
    ACTION_ATTACH_FILES,
    ACTION_ADD_MESSAGE,
    ACTION_ADD_MULTIPLE_MESSAGES,
    ACTION_ADD_PARTICIPANT,
    ACTION_DETACH_FILES,
    ACTION_REMOVE_MESSAGE,
    ACTION_REMOVE_PARTICIPANT,
    ACTION_SET_CHAT_PROACTIVE_MESSAGE_LINK,
    ACTION_START_SESSION,
    ACTION_UPDATE_CONVERSATION_STATE,
    ACTION_UPDATE_MESSAGE,
    ACTION_UPDATE_PARTICIPANT,
    ACTION_UPDATE_MESSAGE_INPUT,
    ACTION_ADD_CLOSED_CONVERSATIONS,
    ACTION_SELECT_CHAT_ROUTE,
    ACTION_SELECT_CONVERSATION,
    ACTION_PREVIEW_MEDIA
} from "./actions/actionTypes";
import { Client } from "../__mocks__/@twilio/conversations";
import { ClosedChat } from "../modules/chatHistory/chatHistory.entities";

const initialState: ChatState = { chatRoute: "active" };

function detachFiles(attachedFiles: File[] = [], filesToDetach: File[] = []): File[] {
    return (attachedFiles || []).filter(
        (file: File) =>
            !filesToDetach.some(
                (fileToDetach: File) =>
                    file.name === fileToDetach.name &&
                    file.type === fileToDetach.type &&
                    file.size === fileToDetach.size
            )
    );
}

type Payload = {
    [ACTION_START_SESSION]: {
        conversationsClient?: Client;
        conversation?: Conversation;
        conversationState?: string;
        users?: User[];
        participants?: Participant[];
        messages?: Message[];
    };
    [ACTION_ADD_MULTIPLE_MESSAGES]: {
        messages: Message[];
    };
    [ACTION_ADD_MESSAGE]: { message: Message };
    [ACTION_REMOVE_MESSAGE]: { message: Message };
    [ACTION_UPDATE_MESSAGE]: { message: Message };
    [ACTION_ATTACH_FILES]: { filesToAttach: File[] };
    [ACTION_DETACH_FILES]: { filesToDetach: File[] };
    [ACTION_ADD_PARTICIPANT]: { participant: Participant; user: User };
    [ACTION_REMOVE_PARTICIPANT]: { participant: Participant };
    [ACTION_UPDATE_PARTICIPANT]: { participant: Participant };
    [ACTION_UPDATE_CONVERSATION_STATE]: { conversationState: string };
    [ACTION_UPDATE_MESSAGE_INPUT]: { inputMessage: string };
    [ACTION_ADD_CLOSED_CONVERSATIONS]: ClosedChat[];
    [ACTION_SELECT_CHAT_ROUTE]: "active" | "closed";
    [ACTION_SELECT_CONVERSATION]: string | undefined;
    [ACTION_SET_CHAT_PROACTIVE_MESSAGE_LINK]: { messageId: string };
    [ACTION_PREVIEW_MEDIA]: { media: Media | undefined };
};

// Temporal for validate that Payload actions are defined

export type ChatActions = ActionPayloadType<Payload>;

export const ChatReducer: Reducer<ChatState, ChatActions> = (state: ChatState = initialState, action) => {
    switch (action.type) {
        case ACTION_START_SESSION: {
            return {
                ...state,
                conversationsClient: action.payload.conversationsClient,
                conversation: action.payload.conversation,
                conversationState: action.payload.conversationState,
                selectedConversationId:
                    action.payload.conversationState === "active" ? action.payload.conversation?.sid : undefined,
                users: action.payload.users,
                participants: action.payload.participants,
                messages: action.payload.messages
            };
        }
        case ACTION_ADD_MULTIPLE_MESSAGES: {
            return {
                ...state,
                messages: [...action.payload.messages, ...(state.messages || [])]
            };
        }
        case ACTION_ADD_MESSAGE: {
            return {
                ...state,
                messages: [...(state.messages || []), action.payload.message]
            };
        }
        case ACTION_REMOVE_MESSAGE: {
            return {
                ...state,
                messages: [...(state.messages || []).filter((m) => m.sid !== action.payload.message.sid)]
            };
        }
        case ACTION_UPDATE_MESSAGE: {
            return {
                ...state,
                messages: [
                    ...(state.messages || []).reduce((acc: Message[], m) => {
                        if (m.sid === action.payload.message.sid) {
                            acc.push(action.payload.message);
                        } else {
                            acc.push(m);
                        }
                        return acc;
                    }, [])
                ]
            };
        }
        case ACTION_ATTACH_FILES: {
            return {
                ...state,
                attachedFiles: [...(state.attachedFiles || []), ...action.payload.filesToAttach]
            };
        }
        case ACTION_DETACH_FILES: {
            return {
                ...state,
                attachedFiles: detachFiles(state.attachedFiles, action.payload.filesToDetach)
            };
        }
        case ACTION_PREVIEW_MEDIA: {
            return {
                ...state,
                previewMedia: action.payload.media
            };
        }
        case ACTION_ADD_PARTICIPANT: {
            return {
                ...state,
                participants: [...(state.participants || []), action.payload.participant],
                users: [...(state.users || []), action.payload.user]
            };
        }
        case ACTION_REMOVE_PARTICIPANT: {
            return {
                ...state,
                participants: [...(state.participants || []).filter((p) => p.sid !== action.payload.participant.sid)],
                users: [...(state.users || []).filter((u) => u.identity !== action.payload.participant.identity)]
            };
        }
        case ACTION_SET_CHAT_PROACTIVE_MESSAGE_LINK: {
            return {
                ...state,
                proactiveMessageId: action.payload.messageId
            };
        }
        case ACTION_UPDATE_PARTICIPANT: {
            return {
                ...state,
                participants: [
                    ...(state.participants || []).reduce((acc: Participant[], p) => {
                        if (p.sid === action.payload.participant.sid) {
                            acc.push(action.payload.participant);
                        } else {
                            acc.push(p);
                        }
                        return acc;
                    }, [])
                ]
            };
        }
        case ACTION_UPDATE_CONVERSATION_STATE: {
            return {
                ...state,
                conversationState: action.payload.conversationState,
                selectedConversationId:
                    action.payload.conversationState === "closed" ? undefined : state.selectedConversationId
            };
        }

        case ACTION_UPDATE_MESSAGE_INPUT: {
            return {
                ...state,
                inputMessage: action.payload.inputMessage
            };
        }

        case ACTION_ADD_CLOSED_CONVERSATIONS: {
            return {
                ...state,
                closedChats: action.payload
            };
        }

        case ACTION_SELECT_CHAT_ROUTE: {
            return {
                ...state,
                chatRoute: action.payload
            };
        }

        case ACTION_SELECT_CONVERSATION: {
            return {
                ...state,
                selectedConversationId: action.payload
            };
        }

        default:
            return state;
    }
};
