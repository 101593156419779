import { Box } from "@twilio-paste/core/box";
import { ChatIcon } from "@twilio-paste/icons/esm/ChatIcon";
import { CloseIcon } from "@twilio-paste/icons/esm/CloseIcon";
import { useDispatch, useSelector } from "react-redux";
import { useState, useEffect, useMemo } from "react";
import { Badge } from "@twilio-paste/core";

import {
    addNotification,
    changeEngagementPhase,
    changeExpandedStatus,
    deleteAllProactiveSupportMessages,
    deleteByIdProactiveSupportMessages,
    openMessageProactiveSupport,
    setConversationProactiveLink
} from "../store/actions/genericActions";
import { AppState, EngagementPhase } from "../store/definitions";
import { containerStyles } from "./styles/EntryPoint.styles";
import { useDevice } from "../hooks/useDevice";
import { ProactiveSupportMessage } from "./ProactiveSupportMessage";
import { useNotifications } from "../hooks/useNotifications";
import { MAX_SHOW_PROACTIVE_SUPPORT_MESSAGES_UI, PREFIX_PROACTIVE_SUPPORT } from "../constants";
import { ProactiveSupportMessage as ProactiveSupportMessageType } from "../modules/proactiveSupport/proactiveSupport.entities";
import { useTranslation } from "../hooks/useTranslation";
import { UrlValidator } from "../utils/UrlValidator";
import { SendMessageProactiveSupportUseCase } from "../modules/proactiveSupport/useCases/sendMessageProactiveSupport.useCase";
import { useEvents } from "../modules/proactiveSupport/hooks/useEvents";

export const EntryPoint = () => {
    const { isMobile } = useDevice();
    const dispatch = useDispatch();
    const notifications = useNotifications();
    const t = useTranslation();
    const expanded = useSelector((state: AppState) => state.session.expanded);
    const { onMessageShown, onMessageClicked } = useEvents();

    const proactiveSupportMessages = useSelector((state: AppState) => {
        const messages = state?.proactiveSupport?.messages || [];
        const currentEventTrigger = state?.proactiveSupport?.currentEventTrigger || [];

        return messages
            .map((message) => message)
            .filter((message) => {
                if (message.eventTrigger === null) return true;
                return message.eventTrigger === currentEventTrigger;
            })
            .filter((message) =>
                new UrlValidator(message.ruleSetOperator, message.ruleSets).match(window.location.href)
            )
            .filter((message, index) => index < MAX_SHOW_PROACTIVE_SUPPORT_MESSAGES_UI);
    });

    const conversation = useSelector((state: AppState) => state.chat.conversation);

    const hideChatBubble = useSelector((state: AppState) => state.config.hideChatBubble);
    const [openMessages, setOpenMessages] = useState<boolean>(false);

    const showProactiveSupportMessages = proactiveSupportMessages.length > 0 && !expanded;
    const totalMessageShow = openMessages ? proactiveSupportMessages.length : 0;

    const stylesProactiveSupportMessages: {
        container: React.CSSProperties;
        containerButtonClean: React.CSSProperties;
    } = {
        container: {
            minHeight: `${128 * totalMessageShow}px`,
            gap: "8px",
            paddingTop: "170px",
            position: "relative",
            paddingBottom: "8px"
        },
        containerButtonClean: {
            top: 8,
            cursor: "pointer",
            position: `absolute`,
            width: "max-content",
            opacity: openMessages ? 1 : 0,
            transition: `opacity 200ms cubic-bezier(0.165, 0.84, 0.44, 1) 400ms`
        }
    };

    const handleMouseOver = () => {
        if (!openMessages) {
            setOpenMessages(true);
        }
    };

    const handleMouseOut = () => {
        if (openMessages) {
            setOpenMessages(false);
        }
    };

    const handleDeleteAllProactiveSupportMessages = () => {
        dispatch(deleteAllProactiveSupportMessages());
    };

    const sendMessageWhenChatIsOpen = async (query: string): Promise<void> => {
        if (!conversation) return;
        let preparedMessage = conversation.prepareMessage();
        preparedMessage = preparedMessage.setBody(query);
        await preparedMessage.build().send();
    };

    //
    const handleSendMessageProactiveSupport = async (
        message: ProactiveSupportMessageType,
        textVersionId: string
    ): Promise<void> => {
        try {
            const content = message.textVersions.find((textVersion) => textVersion.uuid === textVersionId)?.text;
            if (!content) {
                throw new Error(t.i18n.notificationPleaseTryAgain);
            }
            const query = `${content} ${PREFIX_PROACTIVE_SUPPORT}`;
            const chatIsOpen = Boolean(conversation);

            const sendMessageProactiveSupportUseCase = new SendMessageProactiveSupportUseCase();

            sendMessageProactiveSupportUseCase.execute({
                messageId: message.uuid,
                textVersionId
            });

            dispatch(openMessageProactiveSupport(message.uuid, textVersionId));
            onMessageClicked(message.uuid);

            if (chatIsOpen) {
                sendMessageWhenChatIsOpen(query).then(() => {
                    dispatch(deleteByIdProactiveSupportMessages(message.uuid));
                    dispatch(changeExpandedStatus({ expanded: !expanded }));
                    dispatch(setConversationProactiveLink(message.uuid));
                });
                return;
            }
            dispatch(setConversationProactiveLink(message.uuid));
            dispatch(changeExpandedStatus({ expanded: !expanded }));
            dispatch(changeEngagementPhase({ phase: EngagementPhase.PreviewProactiveSupportMessage }));
        } catch (err) {
            dispatch(addNotification(notifications.failedToInitSessionNotification((err as Error).message)));
            dispatch(changeEngagementPhase({ phase: EngagementPhase.PreEngagementForm }));
        }
    };

    // eslint-disable-next-line @typescript-eslint/require-array-sort-compare
    const messageIds = proactiveSupportMessages
        .map((message) => message.uuid)
        .sort()
        .join(",");

    const messagesShown = useMemo(() => messageIds.split(","), [messageIds]);

    useEffect(() => {
        for (const message of messagesShown) {
            if (message) {
                onMessageShown(message);
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [messagesShown]);

    if (isMobile && expanded) return null;

    return (
        <>
            {showProactiveSupportMessages && (
                <Box
                    display="flex"
                    className="container-proactive-support-messages"
                    flexDirection="column"
                    alignItems="flex-end"
                    justifyContent="end"
                    onMouseOver={() => handleMouseOver()}
                    onMouseOut={() => handleMouseOut()}
                    style={stylesProactiveSupportMessages.container}
                >
                    <Box
                        onMouseOver={() => handleMouseOver()}
                        onMouseOut={() => handleMouseOut()}
                        onClick={handleDeleteAllProactiveSupportMessages}
                        style={stylesProactiveSupportMessages.containerButtonClean}
                    >
                        <Badge as="span" variant="default">
                            {t.i18n.proactiveSupportMessageButtonCleanAll}
                            <CloseIcon decorative />
                        </Badge>
                    </Box>

                    {proactiveSupportMessages.map((message, index: number) => (
                        <ProactiveSupportMessage
                            key={message.uuid}
                            message={message}
                            numItem={index + 1}
                            isPrincipal={index + 1 === proactiveSupportMessages.length}
                            openMessages={openMessages}
                            onClick={async (textVersionId: string) =>
                                handleSendMessageProactiveSupport(message, textVersionId)
                            }
                        />
                    ))}
                </Box>
            )}
            {!hideChatBubble && (
                <Box
                    as="button"
                    className="entry-point-button"
                    data-test="entry-point-button"
                    onClick={() => dispatch(changeExpandedStatus({ expanded: !expanded }))}
                    style={{
                        boxShadow: "rgba(35, 47, 53, 0.09) 0px 2px 8px 0px"
                    }}
                    {...containerStyles}
                >
                    {expanded ? (
                        <CloseIcon decorative={false} title="Minimize chat" size="sizeIcon60" />
                    ) : (
                        <ChatIcon decorative={false} title="Open chat" size="sizeIcon60" />
                    )}
                </Box>
            )}
        </>
    );
};
