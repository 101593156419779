import { AnyAction, Reducer } from "redux";

import { saveProactiveSupportCurrentIds, saveProactiveSupportMessages } from "../utils/storageProactiveSupport";
import {
    ACTION_ANSWER_PROACTIVE_SUPPORT,
    ACTION_DELETE_ALL_PROACTIVE_SUPPORT_MESSAGES,
    ACTION_DELETE_PROACTIVE_SUPPORT_MESSAGE,
    ACTION_OPEN_MESSAGE_PROACTIVE_SUPPORT,
    ACTION_UPDATE_CURRENT_EVENT_TRIGGER,
    ACTION_UPDATE_PROACTIVE_SUPPORT_MESSAGES
} from "./actions/actionTypes";
import { ProactiveSupport } from "./definitions";

const initialState: ProactiveSupport = {
    messages: [],
    currentEventTrigger: ""
};

export const ProactiveSupportReducer: Reducer = (
    state: ProactiveSupport = initialState,
    action: AnyAction
): ProactiveSupport => {
    switch (action.type) {
        case ACTION_UPDATE_PROACTIVE_SUPPORT_MESSAGES:
            saveProactiveSupportMessages(action.payload.messages);
            return {
                ...state,
                messages: action.payload.messages,
                currentMessageId: action.payload.currentMessageId,
                currentTextVersionId: action.payload.currentTextVersionId
            };

        case ACTION_ANSWER_PROACTIVE_SUPPORT: {
            saveProactiveSupportCurrentIds("", "");
            return {
                ...state,
                currentMessageId: "",
                currentTextVersionId: ""
            };
        }
        case ACTION_OPEN_MESSAGE_PROACTIVE_SUPPORT:
            saveProactiveSupportCurrentIds(action.payload.currentMessageId, action.payload.currentTextVersionId);
            return {
                ...state,
                currentMessageId: action.payload.currentMessageId,
                currentTextVersionId: action.payload.currentTextVersionId
            };

        case ACTION_DELETE_ALL_PROACTIVE_SUPPORT_MESSAGES:
            const messagesWithEventTrigger = state.messages.filter((message) => Boolean(message.eventTrigger));

            saveProactiveSupportMessages(messagesWithEventTrigger);

            return {
                currentEventTrigger: "",
                messages: messagesWithEventTrigger
            };

        case ACTION_DELETE_PROACTIVE_SUPPORT_MESSAGE:
            const { id } = action.payload;

            const messageToDelete = state?.messages.find((message) => message.uuid === id);

            if (messageToDelete && Boolean(messageToDelete.eventTrigger)) {
                return {
                    ...state,
                    currentEventTrigger: ""
                };
            }

            const newMessages = state.messages.filter((message) => message.uuid !== id);

            saveProactiveSupportMessages(newMessages);

            return {
                ...state,
                messages: newMessages
            };

        case ACTION_UPDATE_CURRENT_EVENT_TRIGGER:
            let messages = state.messages || [];

            const messageWithEventTrigger = state.messages.find(
                (message) => message.eventTrigger === action.payload.currentEventTrigger
            );

            if (messageWithEventTrigger) {
                messages = messages?.filter((message) => message.uuid !== messageWithEventTrigger.uuid);
                messages = [...messages, messageWithEventTrigger];
            }

            saveProactiveSupportMessages(messages);
            return {
                messages,
                currentEventTrigger: action.payload.currentEventTrigger
            };

        default:
            return state;
    }
};
