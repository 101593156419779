import { UseCaseBase } from "../../../utils/UseCaseBase";
import { saveTextVersionStat } from "../proactiveSupport.repository";

type SendMessageProactiveSupportArguments = {
    messageId: string;
    textVersionId: string;
};

export class SendMessageProactiveSupportUseCase extends UseCaseBase<SendMessageProactiveSupportArguments, void> {
    async execute(params: SendMessageProactiveSupportArguments): Promise<void> {
        saveTextVersionStat(params.messageId, params.textVersionId, "opened_count");
    }
}
