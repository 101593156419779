import { FormEventHandler } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Text } from "@twilio-paste/core";
import { useTheme } from "@twilio-paste/theme";
import { Box } from "@twilio-paste/core/box";
import { Dispatch } from "redux";

import { AppState } from "../store/definitions";
import { ConversationEnded } from "./ConversationEnded";
import { introStyles, titleStyles, formStyles } from "./styles/PreEngagementFormPhase.styles";
import { useTranslation } from "../hooks/useTranslation";
import { ChatActions } from "../store/chat.reducer";
import ConversationItem from "./ConversationItem";

export const HomeWrapper: React.FC<React.PropsWithChildren<{ onSubmit?: FormEventHandler<HTMLElement> }>> = ({
    onSubmit,
    children
}) => {
    const theme = useTheme();
    const { i18n } = useTranslation();
    return (
        <Box
            style={{
                background: theme.backgroundColors.colorBackgroundBrand
            }}
            as="form"
            paddingX="space100"
            data-test="pre-engagement-chat-form"
            onSubmit={onSubmit}
            {...formStyles}
        >
            <Text {...titleStyles} as="h3">
                {i18n.engagementFormTitle}
                <span role="img" aria-label="donut">
                    {i18n.engagementFormTitleIcon}
                </span>
            </Text>

            <Text {...introStyles} as="h4">
                {i18n.engagementFormSubTitle}
            </Text>
            {children}
        </Box>
    );
};

const Home = () => {
    const chatState = useSelector(
        ({ chat: { conversationState, chatRoute, closedChats, participants, messages } }: AppState) => ({
            conversationState,
            chatRoute,
            closedChats,
            participants,
            messages
        })
    );
    const { conversationSid: activeConversationId, conversation } = useSelector((state: AppState) => state.session);
    const dispatchChat = useDispatch<Dispatch<ChatActions>>();
    const { conversationState, participants, messages } = chatState;

    return (
        <div style={{ flex: 1, flexGrow: 1, backgroundColor: "colorBackground" }}>
            {conversationState === "active" ? (
                <HomeWrapper>
                    <Box
                        onClick={() => {
                            dispatchChat({ type: "ACTION_SELECT_CONVERSATION", payload: activeConversationId });
                        }}
                        style={{
                            marginTop: 60,
                            // height: 60,
                            display: "flex",
                            flex: 1,
                            flexDirection: "column",
                            alignItems: "flex-start",
                            paddingTop: 20,
                            backgroundColor: "Background",
                            overflow: "hidden",
                            borderRadius: 12,
                            cursor: "pointer"
                        }}
                    >
                        <div
                            style={{
                                marginLeft: 20,
                                fontWeight: 600,
                                fontSize: 14,
                                lineHeight: "150%",
                                padding: 0
                            }}
                        >
                            Mensaje reciente:
                        </div>
                        <ConversationItem
                            participants={participants || []}
                            lastMessage={messages?.slice(-1)[0].body}
                            updatedAt={conversation?.dateUpdated}
                        />
                    </Box>
                </HomeWrapper>
            ) : (
                <ConversationEnded />
            )}
        </div>
    );
};

export default Home;
