import { UIEvent, useRef } from "react";
import { useSelector } from "react-redux";
import { Box } from "@twilio-paste/core/box";
import { Text } from "@twilio-paste/core/text";
import { Spinner } from "@twilio-paste/core/spinner";
import { Message } from "@twilio/conversations";
import throttle from "lodash.throttle";

import { MessageBubble } from "./MessageBubble";
import { AppState } from "../store/definitions";
import {
    conversationEventContainerStyles,
    conversationEventTitleStyles,
    conversationEventDateStyles,
    spinnerContainerStyles,
    messageListStyles,
    innerContainerStyles,
    outerContainerStyles
} from "./styles/MessageList.styles";
import { useTranslation } from "../hooks/useTranslation";
import { MessageListSeparator } from "./MessageListSeparator";
import { getDaysOld } from "../utils/getDaysOld";
import { getCurrentUserIdentity } from "../utils/identityHandlers";

const isLastOfUserGroup = (message: Message, i: number, messages: Message[]) => {
    const nextMessage = messages[i + 1];

    // if there's no message afterwards, it's definitely the last of a group
    if (!nextMessage) {
        return true;
    }

    // if the author of the next message is different from current one's, then yes, this message is last of its group
    return nextMessage.author !== message.author;
};
const isFirstOfDateGroup = (message: Message, i: number, messages: Message[]) => {
    const prevMessage = messages[i - 1];

    // if the previous message has a date older than the current message, this message is the first for this date
    return getDaysOld(prevMessage.dateCreated) > getDaysOld(message.dateCreated);
};

export const MessageListStatic = ({ messages }: { messages: Message[]; isLoadingMessages?: boolean }) => {
    const { conversation, conversationsClient } = useSelector((state: AppState) => ({
        messages: state.chat.messages,
        participants: state.chat.participants,
        users: state.chat.users,
        conversation: state.chat.conversation,
        conversationsClient: state.chat.conversationsClient
    }));
    const storedIdentity = getCurrentUserIdentity();
    // eslint-disable-next-line no-nested-ternary
    const userId = messages.find((message) => message.author === storedIdentity.flex)
        ? storedIdentity.flex
        : messages.find((message) => message.author === conversationsClient?.user.identity)
        ? conversationsClient?.user.identity
        : messages[0].author;
    const hasLoadedAllMessages = true;
    const { i18n } = useTranslation();
    const messageListRef = useRef<HTMLDivElement>(null);

    // eslint-disable-next-line @typescript-eslint/no-unused-vars, no-empty-function, @typescript-eslint/no-empty-function
    const handleScroll = async (event: UIEvent<HTMLDivElement>) => {};

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const renderSeparatorIfApplicable = (message: Message, i: number) => {
        /*
         * Render date separator above the first message which is the first of a certain date
         * (the first message of any chunk cannot compare itself with previous,
         * and the i = 0 date separator is rendered before "Chat started" section)
         */
        if (i > 0 && isFirstOfDateGroup(message, i, messages as Message[])) {
            return <MessageListSeparator message={message} separatorType="date" />;
        }
        return null;
    };

    const renderChatStarted = () => (
        <Box {...conversationEventContainerStyles}>
            <Text as="h3" {...conversationEventTitleStyles} data-test="chat-started">
                {i18n.messagingChatStarted}
            </Text>
            <Text as="p" {...conversationEventDateStyles}>
                {conversation?.dateCreated.toLocaleString()}
            </Text>
        </Box>
    );

    const renderChatItems = () => {
        if (!messages) {
            return null;
        }

        /*
         * We use a copy of the messages array where the first message is a placeholder for the loading spinner.
         * By assigning the loading spinner to the same index and key as the previous message,
         * we avoid a snappy scroll position change when loading spinner disappears.
         */
        const spinnerIndex = (messages[0]?.index || 0) - 1;
        const messagesWithSpinner = [
            {
                index: spinnerIndex
            } as Message,
            ...messages
        ];

        return messagesWithSpinner.map((message: Message, i: number) => {
            // First message in array represents the loading spinner
            if (message.index === spinnerIndex) {
                // Only render loading spinner if there are remaining messages to load
                return hasLoadedAllMessages ? null : (
                    <Box {...spinnerContainerStyles} key={message.index}>
                        <Spinner color="colorTextWeak" decorative={false} title="Loading" />
                    </Box>
                );
            }
            // Discount loading spinner from indices
            i -= 1;

            return (
                <Box data-test="all-message-bubbles" key={message.index}>
                    {renderSeparatorIfApplicable(message, i)}
                    <MessageBubble
                        message={message}
                        isLast={i === messages.length - 1}
                        isLastOfUserGroup={isLastOfUserGroup(message, i, messages)}
                        focusable={false}
                        // eslint-disable-next-line no-empty-function, @typescript-eslint/no-empty-function
                        updateFocus={() => {}}
                        isUser={message.author === userId}
                    />
                </Box>
            );
        });
    };

    // eslint-disable-next-line no-empty-function, @typescript-eslint/no-empty-function
    const handleFocus = () => {};

    return (
        <Box {...messageListStyles}>
            <Box {...outerContainerStyles} onScroll={throttle(handleScroll, 1000)} ref={messageListRef} role="main">
                <Box
                    aria-label="Chat messages"
                    role="log"
                    aria-relevant="additions"
                    {...innerContainerStyles}
                    onFocus={handleFocus}
                >
                    {renderChatStarted()}
                    {renderChatItems()}
                </Box>
            </Box>
        </Box>
    );
};
