import { Dispatch } from "redux";
import { Conversation, Media } from "@twilio/conversations";

import { EngagementPhase, Notification, PreEngagementData } from "../definitions";
import {
    ACTION_ADD_MULTIPLE_MESSAGES,
    ACTION_ADD_NOTIFICATION,
    ACTION_ANSWER_PROACTIVE_SUPPORT,
    ACTION_ATTACH_FILES,
    ACTION_CHANGE_ENGAGEMENT_PHASE,
    ACTION_CHANGE_EXPANDED_STATUS,
    ACTION_DELETE_ALL_PROACTIVE_SUPPORT_MESSAGES,
    ACTION_DELETE_PROACTIVE_SUPPORT_MESSAGE,
    ACTION_DETACH_FILES,
    ACTION_PREVIEW_MEDIA,
    ACTION_OPEN_MESSAGE_PROACTIVE_SUPPORT,
    ACTION_REMOVE_NOTIFICATION,
    ACTION_SET_CHAT_PROACTIVE_MESSAGE_LINK,
    ACTION_UPDATE_CURRENT_EVENT_TRIGGER,
    ACTION_UPDATE_MESSAGE_INPUT,
    ACTION_UPDATE_PRE_ENGAGEMENT_DATA,
    ACTION_UPDATE_PROACTIVE_SUPPORT_MESSAGES
} from "./actionTypes";
import { MESSAGES_LOAD_COUNT } from "../../constants";
import { ProactiveSupportMessage } from "../../modules/proactiveSupport/proactiveSupport.entities";

export function changeEngagementPhase({ phase }: { phase: EngagementPhase }) {
    return {
        type: ACTION_CHANGE_ENGAGEMENT_PHASE,
        payload: {
            currentPhase: phase
        }
    };
}

export function addNotification(notification: Notification) {
    return {
        type: ACTION_ADD_NOTIFICATION,
        payload: {
            notification
        }
    };
}

export function removeNotification(id: string) {
    return {
        type: ACTION_REMOVE_NOTIFICATION,
        payload: {
            id
        }
    };
}

export function getMoreMessages({ anchor, conversation }: { anchor: number; conversation: Conversation }) {
    return async (dispatch: Dispatch) =>
        dispatch({
            type: ACTION_ADD_MULTIPLE_MESSAGES,
            payload: {
                messages: (await conversation.getMessages(MESSAGES_LOAD_COUNT, anchor)).items
            }
        });
}

export function changeExpandedStatus({ expanded }: { expanded: boolean }) {
    return {
        type: ACTION_CHANGE_EXPANDED_STATUS,
        payload: {
            expanded
        }
    };
}

export function attachFiles(files: File[]) {
    return {
        type: ACTION_ATTACH_FILES,
        payload: {
            filesToAttach: files
        }
    };
}

export function detachFiles(files: File[]) {
    return {
        type: ACTION_DETACH_FILES,
        payload: {
            filesToDetach: files
        }
    };
}

export function previewMedia(file: Media | undefined) {
    return {
        type: ACTION_PREVIEW_MEDIA,
        payload: {
            media: file
        }
    };
}

export function updatePreEngagementData(data: Partial<PreEngagementData>) {
    return {
        type: ACTION_UPDATE_PRE_ENGAGEMENT_DATA,
        payload: {
            preEngagementData: data
        }
    };
}

export function updateMessageInput(inputMessage: string) {
    return {
        type: ACTION_UPDATE_MESSAGE_INPUT,
        payload: {
            inputMessage
        }
    };
}

export function updateProactiveSupportMessages(
    messages: ProactiveSupportMessage[],
    currentMessageId: string,
    currentTextVersionId: string
) {
    return {
        type: ACTION_UPDATE_PROACTIVE_SUPPORT_MESSAGES,
        payload: {
            messages,
            currentMessageId,
            currentTextVersionId
        }
    };
}

export function deleteAllProactiveSupportMessages() {
    return {
        type: ACTION_DELETE_ALL_PROACTIVE_SUPPORT_MESSAGES
    };
}

export function deleteByIdProactiveSupportMessages(id: string) {
    return {
        type: ACTION_DELETE_PROACTIVE_SUPPORT_MESSAGE,
        payload: {
            id
        }
    };
}

export function updateCurrentEventTrigger(eventTrigger: string) {
    return {
        type: ACTION_UPDATE_CURRENT_EVENT_TRIGGER,
        payload: {
            currentEventTrigger: eventTrigger
        }
    };
}

export function answerProactiveSupport() {
    return {
        type: ACTION_ANSWER_PROACTIVE_SUPPORT,
        payload: {
            currentMessageId: "",
            currentTextVersionId: ""
        }
    };
}

export function openMessageProactiveSupport(currentMessageId: string, currentTextVersionId: string) {
    return {
        type: ACTION_OPEN_MESSAGE_PROACTIVE_SUPPORT,
        payload: {
            currentMessageId,
            currentTextVersionId
        }
    };
}

export function setConversationProactiveLink(messageId: string | null) {
    return {
        type: ACTION_SET_CHAT_PROACTIVE_MESSAGE_LINK,
        payload: {
            messageId
        }
    };
}
