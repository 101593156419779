import {
    GetMessageProactiveSupportArguments,
    ProactiveSupportMessage,
    textVersionStat
} from "./proactiveSupport.entities";
import { InfraProactiveSupportMessage } from "./proactiveSupport.entities.api";
import { toProactiveSupportMessage } from "./proactiveSupport.adapters";
import { getProactiveSupportMessages } from "../../utils/storageProactiveSupport";

const headers = {
    Accept: "application/json",
    "Content-Type": "application/json"
};

export const getProactiveSupportMessagesRepo = async (
    params: GetMessageProactiveSupportArguments
): Promise<ProactiveSupportMessage[]> => {
    const cachedData = getProactiveSupportMessages();

    if (cachedData !== null) {
        return cachedData;
    }

    const response = await fetch(`${process.env.CONTACT_CENTER_API}/api/v1/messages/by_brand/${params.brand}`, {
        method: "GET",
        headers
    });

    if (!response.ok) {
        throw new Error("Request to backend failed");
    }

    const data = await response.json();
    const messagesInfra = data as InfraProactiveSupportMessage[];
    return messagesInfra.map(toProactiveSupportMessage);
};

export const saveTextVersionStat = async (
    messageId: string,
    textVersionId: string,
    stat: textVersionStat
): Promise<void> => {
    return fetch(
        `${process.env.CONTACT_CENTER_API}/api/v1/messages/${messageId}/text_versions/${textVersionId}/increment_count?stat=${stat}`,
        {
            method: "PUT",
            headers
        }
    ) as unknown as Promise<void>;
};
