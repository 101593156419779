import { Box, Text } from "@twilio-paste/core";
import { UserIcon } from "@twilio-paste/icons/esm/UserIcon";

import { messageListStyles, outerContainerStyles } from "./styles/MessageList.styles";
import {
    bubbleAndAvatarContainerStyles,
    bodyStyles,
    getAvatarContainerStyles,
    getInnerContainerStyles
} from "./styles/MessageBubble.styles";

const ProactiveSupportMessagePreview = ({ message }: { message: string }) => {
    const ContainerMain = ({ children }: { children: JSX.Element }) => (
        <Box {...messageListStyles} marginTop="space100">
            <Box {...outerContainerStyles} flexDirection="column" role="main">
                <Box marginTop="space100">
                    <Box {...outerContainerStyles} data-message-bubble data-testid="message-bubble">
                        {children}
                    </Box>
                </Box>
            </Box>
        </Box>
    );

    return (
        <ContainerMain>
            <Box {...bubbleAndAvatarContainerStyles}>
                <Box {...getAvatarContainerStyles(false)} data-testid="avatar-container">
                    <UserIcon decorative={true} size="sizeIcon40" />
                </Box>
                <Box {...getInnerContainerStyles(false)}>
                    <Text as="p" {...bodyStyles}>
                        {message}
                    </Text>
                </Box>
            </Box>
        </ContainerMain>
    );
};

export default ProactiveSupportMessagePreview;
