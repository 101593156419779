import log from "loglevel";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Box } from "@twilio-paste/core/box";
import { Text } from "@twilio-paste/core/text";
import { FileIcon } from "@twilio-paste/icons/esm/FileIcon";
import { CloseIcon } from "@twilio-paste/icons/esm/CloseIcon";
import { Button } from "@twilio-paste/core/button";
import { Media } from "@twilio/conversations";
import { extension as mimeToExtension } from "mime-types";
import { Truncate } from "@twilio-paste/core/truncate";

import { addNotification, detachFiles, previewMedia } from "../store/actions/genericActions";
import { AppState } from "../store/definitions";
import { roundFileSizeInMB } from "../utils/roundFileSizeInMB";
import {
    actionIconContainerStyles,
    fileDescriptionContainerStyles,
    fileIconContainerStyles,
    fileNameStyles,
    fileSizeStyles,
    getContainerStyles,
    outerContainerStyles
} from "./styles/FilePreview.styles";
import { useNotifications } from "../hooks/useNotifications";

interface FilePreviewProps {
    file: File;
    isBubble: boolean;
    disabled?: boolean;
    media?: Media;
    focusable: boolean;
}

export const forceDownload = async (url: string, filename: string) => {
    const response = await fetch(url);
    if (!response.ok) throw new Error("Network response was not ok");
    const blob = await response.blob();
    const link = document.createElement("a");
    link.href = URL.createObjectURL(blob);
    link.setAttribute("download", filename);
    document.body.appendChild(link);
    link.click();
    link.remove();
};

export const FilePreview = (props: FilePreviewProps) => {
    const notifications = useNotifications();

    const { file, isBubble, disabled, media, focusable } = props;
    const [isHovered, setIsHovered] = useState(false);
    const [mediaUrl, setMediaUrl] = useState<string | undefined>(undefined);

    const dispatch = useDispatch();
    const fileAttachmentConfig = useSelector((state: AppState) => state.config.fileAttachment);

    const handleDetach = () => {
        dispatch(detachFiles([file]));
    };

    useEffect(() => {
        if (media && media.contentType.startsWith("image"))
            media.getContentTemporaryUrl().then((url) => setMediaUrl(url));
    }, [media]);

    const handleDownload = async () => {
        if (fileAttachmentConfig?.maxFileSize && file.size > fileAttachmentConfig.maxFileSize) {
            dispatch(
                addNotification(
                    notifications.fileDownloadInvalidSizeNotification({
                        fileName: file.name,
                        maxFileSize: `${roundFileSizeInMB(fileAttachmentConfig.maxFileSize)}MB`
                    })
                )
            );
            return;
        }

        if (
            fileAttachmentConfig?.acceptedExtensions &&
            !fileAttachmentConfig.acceptedExtensions.includes(mimeToExtension(file.type) as string)
        ) {
            dispatch(
                addNotification(
                    notifications.fileDownloadInvalidTypeNotification({
                        fileName: file.name
                    })
                )
            );
            return;
        }

        try {
            if (media && media.contentType && media.contentType.startsWith("image")) {
                dispatch(previewMedia(media));
                return;
            }
            const url = media ? await media.getContentTemporaryUrl() : URL.createObjectURL(file);
            // window.open(fileUrl, "_top");

            forceDownload(url, file.name);
        } catch (e) {
            dispatch(addNotification(notifications.logInfo(`No se pudo descargar el archivo`)));
            log.error(`Failed downloading message attachment: ${e}`);
        }
    };

    const handleBoxClick = () => {
        // eslint-disable-next-line no-negated-condition
        if (!disabled) {
            handleDownload();
        }
    };

    if (media && mediaUrl) {
        return (
            <Box {...outerContainerStyles}>
                <Box
                    as="button"
                    type="button"
                    appearance="none"
                    data-test="file-preview-main-area"
                    {...getContainerStyles(isBubble, disabled, true)}
                    onClick={handleBoxClick}
                    onMouseEnter={() => setIsHovered(true)}
                    onFocus={() => setIsHovered(true)}
                    onMouseLeave={() => setIsHovered(false)}
                    onBlur={() => setIsHovered(false)}
                    tabIndex={focusable ? 0 : -1}
                >
                    <img style={{ maxWidth: "100%" }} src={mediaUrl} alt="" />
                </Box>
            </Box>
        );
    }

    return (
        <Box {...outerContainerStyles}>
            <Box
                as="button"
                type="button"
                appearance="none"
                data-test="file-preview-main-area"
                {...getContainerStyles(isBubble, disabled)}
                onClick={handleBoxClick}
                onMouseEnter={() => setIsHovered(true)}
                onFocus={() => setIsHovered(true)}
                onMouseLeave={() => setIsHovered(false)}
                onBlur={() => setIsHovered(false)}
                tabIndex={focusable ? 0 : -1}
            >
                <Box {...fileIconContainerStyles}>
                    <FileIcon decorative={false} title="File attachment" size="sizeIcon40" />
                </Box>
                <Box {...fileDescriptionContainerStyles}>
                    <Text as="h1" {...fileNameStyles}>
                        <Truncate title={file.name}>{file.name}</Truncate>
                    </Text>
                    <Text as="p" {...fileSizeStyles}>
                        {isHovered ? "Click to open" : `${roundFileSizeInMB(file.size)}MB`}
                    </Text>
                </Box>
            </Box>
            <Box {...actionIconContainerStyles}>
                {!isBubble && (
                    <Button
                        variant="secondary_icon"
                        size="icon_small"
                        onClick={handleDetach}
                        type="button"
                        data-test="message-file-attachment-remove-button"
                    >
                        <CloseIcon
                            decorative={false}
                            title="Remove file attachment"
                            size="sizeIcon30"
                            color="inherit"
                        />
                    </Button>
                )}
            </Box>
        </Box>
    );
};
