export const Luuna = () => {
    return (
        <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect x="0.9" y="0.9" width="30.2" height="30.2" rx="15.1" fill="#235086" />
            <rect x="0.9" y="0.9" width="30.2" height="30.2" rx="15.1" stroke="#F5F5F5" strokeWidth="0.2" />
            <g clipPath="url(#clip0_18_3)">
                <path d="M4.98874 20.546H7.03468V21.4609H4V11H4.98874V20.546Z" fill="white" />
                <path
                    d="M9.76249 21.6156C10.0756 21.6234 10.3858 21.5539 10.6657 21.4133C11.1539 21.1649 11.4324 20.7635 11.5048 20.2248C11.5288 20.0164 11.5396 19.8067 11.537 19.5969C11.537 17.6449 11.537 13.5471 11.537 13.5471H10.5575C10.5575 13.5471 10.5575 17.7851 10.5575 19.8095C10.5575 20.1027 10.5004 20.3774 10.2404 20.5577C10.1016 20.6587 9.93432 20.7131 9.76267 20.7131C9.59102 20.7131 9.42378 20.6587 9.28497 20.5577C9.02493 20.3763 8.96781 20.1027 8.96781 19.8095C8.96781 17.7865 8.96781 13.5471 8.96781 13.5471H7.98759C7.98759 13.5471 7.98759 17.6456 7.98759 19.5969C7.98511 19.8067 7.99577 20.0163 8.01952 20.2248C8.09047 20.7635 8.37074 21.1645 8.85855 21.4133C9.13846 21.5539 9.44867 21.6234 9.76178 21.6156"
                    fill="white"
                />
                <path
                    d="M14.7654 21.6156C15.0785 21.6234 15.3887 21.5539 15.6686 21.4133C16.1568 21.1649 16.4353 20.7635 16.5077 20.2248C16.5317 20.0164 16.5425 19.8067 16.54 19.5969C16.54 17.6449 16.54 13.5471 16.54 13.5471H15.5601C15.5601 13.5471 15.5601 17.7851 15.5601 19.8095C15.5601 20.1027 15.503 20.3774 15.2433 20.5577C15.1044 20.6587 14.9371 20.7131 14.7654 20.7131C14.5937 20.7131 14.4264 20.6587 14.2875 20.5577C14.0278 20.3763 13.9707 20.1027 13.9707 19.8095C13.9707 17.7865 13.9707 13.5471 13.9707 13.5471H12.9905C12.9905 13.5471 12.9905 17.6456 12.9905 19.5969C12.9881 19.8067 12.9989 20.0164 13.0228 20.2248C13.0937 20.7635 13.3737 21.1645 13.8618 21.4133C14.1417 21.5539 14.4519 21.6234 14.7651 21.6156"
                    fill="white"
                />
                <path
                    d="M26.4454 15.4126C26.4479 15.2028 26.4372 14.9931 26.4135 14.7847C26.3425 14.246 26.0623 13.8449 25.5745 13.5961C25.2945 13.4556 24.9843 13.3861 24.6712 13.3938C24.3581 13.3861 24.0479 13.4556 23.768 13.5961C23.2798 13.8446 23.0013 14.246 22.929 14.7847C22.9049 14.9931 22.8941 15.2028 22.8967 15.4126C22.8967 15.5655 22.8967 15.7203 22.8967 15.8739H23.8758V15.1996C23.8758 14.9065 23.9329 14.6318 24.193 14.4515C24.3318 14.3504 24.499 14.296 24.6707 14.296C24.8423 14.296 25.0096 14.3504 25.1484 14.4515C25.4084 14.6328 25.4655 14.9065 25.4655 15.1996V16.3839C25.4655 16.3839 25.4687 16.4709 25.3556 16.5259C24.9242 16.7388 24.4832 16.9269 24.0461 17.1264C23.349 17.444 22.9293 17.9658 22.9084 18.7494C22.892 19.2311 22.9017 19.7133 22.9375 20.1939C22.9825 20.7433 23.2759 21.1521 23.7662 21.4052C24.2565 21.6582 24.7823 21.6848 25.3027 21.5216C25.8231 21.3583 26.2031 21.0162 26.3596 20.4746C26.4157 20.2904 26.4464 20.0993 26.4507 19.9068L26.4454 15.4126ZM25.4521 19.9735C25.4443 20.3994 25.1196 20.6996 24.6925 20.7106C24.2778 20.7223 23.9333 20.4373 23.912 20.0196C23.8882 19.576 23.9007 19.1306 23.9081 18.6873C23.9081 18.568 23.9398 18.4508 24.0001 18.3479C24.0604 18.2449 24.1471 18.16 24.2512 18.1017C24.6343 17.8841 25.0551 17.6943 25.4631 17.4909C25.4606 17.4909 25.4652 19.1785 25.4513 19.9735H25.4521Z"
                    fill="white"
                />
                <path
                    d="M27.3866 12.8487C27.7254 12.8487 28 12.574 28 12.2351C28 11.8962 27.7254 11.6214 27.3866 11.6214C27.0479 11.6214 26.7732 11.8962 26.7732 12.2351C26.7732 12.574 27.0479 12.8487 27.3866 12.8487Z"
                    fill="white"
                />
                <path
                    d="M19.7684 13.3938C19.4553 13.3861 19.1451 13.4556 18.8651 13.5961C18.377 13.8446 18.0985 14.246 18.0261 14.7847C18.0021 14.9931 17.9913 15.2028 17.9938 15.4126C17.9921 17.3645 17.9938 21.4623 17.9938 21.4623H18.974C18.974 21.4623 18.974 17.2244 18.974 15.2C18.974 14.9068 19.0312 14.6321 19.2912 14.4518C19.43 14.3508 19.5973 14.2964 19.7689 14.2964C19.9406 14.2964 20.1078 14.3508 20.2466 14.4518C20.5066 14.6332 20.5638 14.9068 20.5638 15.2C20.5638 17.2229 20.5638 21.4623 20.5638 21.4623H21.544C21.544 21.4623 21.544 17.3638 21.544 15.4126C21.5465 15.2028 21.5358 14.9931 21.5121 14.7847C21.4411 14.246 21.1608 13.8449 20.673 13.5961C20.3931 13.4556 20.0829 13.3861 19.7698 13.3938"
                    fill="white"
                />
            </g>
            <defs>
                <clipPath id="clip0_18_3">
                    <rect width="24" height="10.626" fill="white" transform="translate(4 11)" />
                </clipPath>
            </defs>
        </svg>
    );
};
