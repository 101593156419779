declare global {
    interface Window {
        dataLayer: Record<string, string | boolean>[];
    }
}

function push(event: Record<string, string | boolean>): void {
    if (window?.dataLayer) {
        window.dataLayer.push(event);
    }
}

// eslint-disable-next-line camelcase
function formatMessageData(messageId: string | undefined | null): { message_id?: string; eventLabel?: string } {
    if (messageId) {
        return {
            // eslint-disable-next-line camelcase
            message_id: messageId,
            eventLabel: `Message ID: ${messageId}`
        };
    }
    return {};
}

export function triggerGtmEvent({
    eventName,
    messageId,
    interactive = true
}: {
    eventName: string;
    messageId?: string | null;
    interactive?: boolean;
}): void {
    push({
        event: eventName,
        eventCategory: "Flex Messenger",
        // eslint-disable-next-line camelcase
        send_to: "<Deprecated>",
        ...(interactive ? {} : { nonInteraction: true }),
        ...formatMessageData(messageId)
    });
}
