import { useEffect } from "react";

import { KeysLocalStorage } from "../definitions";
import { useLocalStorage } from "./useLocalStorage";

interface CampaignDetail {
    utmCampaign: string;
    utmSource: string;
}

const useCampaignInterceptor = () => {
    const [campaignDetail, setCampaignDetail] = useLocalStorage<CampaignDetail | null>(
        KeysLocalStorage.CAMPAIGN_DETAIL,
        null
    );

    useEffect(() => {
        const urlParams = new URLSearchParams(window.location.search);
        const utmCampaign = urlParams.get("utm_campaign");
        const utmSource = urlParams.get("utm_source");
        if (utmCampaign !== null && utmSource !== null) {
            const newCampaignDetail: CampaignDetail = { utmCampaign, utmSource };
            setCampaignDetail(newCampaignDetail);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return { campaignDetail };
};

export default useCampaignInterceptor;
