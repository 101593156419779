import merge from "lodash.merge";
import { render } from "react-dom";
import { Provider } from "react-redux";

import { store } from "./store/store";
import { WebchatWidget } from "./components/WebchatWidget";
import { sessionDataHandler } from "./sessionDataHandler";
import { initConfig } from "./store/actions/initActions";
import { ConfigState } from "./store/definitions";
import { initLogger } from "./logger";
import { ChatDispatcher } from "./utils/ChatDispatcher";
import { getThemeByBrand } from "./utils/getThemeByBrand";
import { I18nProvider } from "./i18n/i18n.provider";

const defaultConfig: ConfigState = {
    serverUrl: "http://localhost:3001",
    hideChatBubble: false,
    theme: {
        isLight: true
    },
    fileAttachment: {
        enabled: true,
        maxFileSize: 16777216, // 16 MB
        acceptedExtensions: ["jpg", "jpeg", "png", "amr", "mp3", "mp4", "pdf", "txt"]
    },
    transcript: {
        downloadEnabled: false,
        emailEnabled: false,
        emailSubject: (agentNames) => {
            let subject = "Transcript of your chat";
            if (agentNames.length > 0) {
                subject = subject.concat(` with ${agentNames[0]}`);
                agentNames.slice(1).forEach((name) => (subject = subject.concat(` and ${name}`)));
            }
            return subject;
        },
        emailContent: (customerName, transcript) => {
            return `<div><h1 style="text-align:center;">Chat Transcript</h1><p>Hello ${customerName},<br><br>Please see below your transcript, with any associated files attached, as requested.<br><br>${transcript}</p></div>`;
        }
    }
};

const initWebchat = async (config: ConfigState) => {
    const theme = getThemeByBrand(config.brand);
    const mergedConfig = merge({}, defaultConfig, config, { theme });

    sessionDataHandler.setEndpoint(mergedConfig.serverUrl);
    store.dispatch(initConfig(mergedConfig));
    initLogger();
    const rootElement = document.getElementById("twilio-webchat-widget-root");

    render(
        <Provider store={store}>
            <I18nProvider>
                <WebchatWidget />
            </I18nProvider>
        </Provider>,
        rootElement
    );

    if (window.Cypress) {
        window.store = store;
    }
    return new ChatDispatcher();
};

declare global {
    interface Window {
        Twilio: {
            initWebchat: (config: ConfigState) => ChatDispatcher;
        };
        TwilioWebChat?: ChatDispatcher;
        Cypress: Cypress.Cypress;
        store: typeof store;
    }
}

if (!window.Twilio) {
    // Expose `initWebchat` function to window object
    Object.assign(window, {
        Twilio: {
            initWebchat
        }
    });
    window.dispatchEvent(new Event("twilioWebchatReady"));
}
