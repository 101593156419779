import { CloseIcon } from "@twilio-paste/icons/esm/CloseIcon";
import { ChevronLeftIcon } from "@twilio-paste/icons/esm/ChevronLeftIcon";
import { Box } from "@twilio-paste/core/box";
import { Button } from "@twilio-paste/core";
import { useDispatch, useSelector } from "react-redux";
import { Dispatch } from "redux";

import { getContainerStyles } from "./styles/Header.styles";
import {
    changeEngagementPhase,
    changeExpandedStatus,
    deleteByIdProactiveSupportMessages
} from "../store/actions/genericActions";
import { getImageByBrand } from "../utils/getImageByBrand";
import { AppState, EngagementPhase } from "../store/definitions";
import { useDevice } from "../hooks/useDevice";
import { ChatActions } from "../store/chat.reducer";

export const Header = () => {
    const { brand, currentPhase, messageId } = useSelector((state: AppState) => ({
        currentPhase: state.session.currentPhase,
        brand: state.config.brand,
        messageId: state.proactiveSupport?.currentMessageId
    }));

    const { selectedConversationId } = useSelector((state: AppState) => ({
        selectedConversationId: state.chat.selectedConversationId,
        chatRoute: state.chat.chatRoute
    }));
    const dispatch = useDispatch();
    const dispatchChat = useDispatch<Dispatch<ChatActions>>();
    const { isMobile } = useDevice();
    const showBack = selectedConversationId !== undefined;

    return (
        <Box as="header" {...getContainerStyles(isMobile, showBack)}>
            {showBack && (
                <Button
                    variant="secondary_icon"
                    size="reset"
                    onClick={() => dispatchChat({ type: "ACTION_SELECT_CONVERSATION", payload: undefined })}
                >
                    <ChevronLeftIcon color="colorTextIcon" decorative={false} size="sizeIcon60" title="close" />
                </Button>
            )}
            <img
                style={{
                    height: showBack ? "20px" : "30px",
                    width: "68px",
                    objectFit: "contain"
                }}
                src={getImageByBrand(brand)}
                alt="Brand logo"
            />

            <Button
                variant="secondary_icon"
                size="reset"
                onClick={() => {
                    dispatch(changeExpandedStatus({ expanded: false }));
                    if (currentPhase === EngagementPhase.PreviewProactiveSupportMessage) {
                        dispatch(changeEngagementPhase({ phase: EngagementPhase.PreEngagementForm }));
                        dispatch(deleteByIdProactiveSupportMessages(messageId || ""));
                    }
                }}
            >
                <CloseIcon color="colorTextIcon" decorative={false} size="sizeIcon40" title="close" />
            </Button>
        </Box>
    );
};
