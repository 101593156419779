import { useDispatch, useSelector } from "react-redux";

import { sessionDataHandler } from "../sessionDataHandler";
import { NewMessageComponent } from "./NewMessageComponent";
import { initSession } from "../store/actions/initActions";
import { useNotifications } from "../hooks/useNotifications";
import { addNotification, changeEngagementPhase } from "../store/actions/genericActions";
import { AppState, EngagementPhase } from "../store/definitions";

export const ConversationEnded = () => {
    const dispatch = useDispatch();
    const { brand, posProfile } = useSelector((state: AppState) => state.config) || {};
    const { name, email } = useSelector((state: AppState) => state.session.preEngagementData) || {};
    const notifications = useNotifications();

    const handleStartNewChat = async (query: string) => {
        dispatch(changeEngagementPhase({ phase: EngagementPhase.Loading }));
        try {
            const data = await sessionDataHandler.fetchAndStoreNewSession({
                formData: {
                    friendlyName: name,
                    email,
                    query,
                    brand,
                    posProfile
                }
            });
            dispatch(initSession({ token: data.token, conversationSid: data.conversationSid, notifications }));
        } catch (err) {
            dispatch(addNotification(notifications.failedToInitSessionNotification((err as Error).message)));
            dispatch(changeEngagementPhase({ phase: EngagementPhase.PreEngagementForm }));
        }
    };

    return (
        <div style={{ flex: 1, flexGrow: 1, backgroundColor: "colorBackground" }}>
            <NewMessageComponent handleSubmit={handleStartNewChat} />
        </div>
    );
};
