export const Nooz = () => {
    return (
        <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect width="30" height="30" rx="15" fill="#FAC831" />
            <g clipPath="url(#clip0_18_13)">
                <path
                    d="M6.9168 15.9577V12.0998H7.85966V17.7624C7.60343 17.7624 7.34286 17.7656 7.08251 17.7585C7.04914 17.7585 7.01211 17.7065 6.9856 17.6719C6.01478 16.408 5.04434 15.1435 4.07429 13.8786C4.04483 13.8353 4.00441 13.8009 3.95726 13.7788V17.7555H3V12.0921C3.288 12.0921 3.57554 12.0894 3.86286 12.0958C3.89714 12.0958 3.93714 12.142 3.96297 12.176C4.90575 13.4038 5.8483 14.6323 6.79063 15.8615C6.82011 15.8996 6.85097 15.9365 6.88206 15.9741L6.9168 15.9577Z"
                    fill="#3D3935"
                />
                <path
                    d="M18.8465 17.8549C17.3768 17.8549 16.2408 16.9313 15.9779 15.469C15.673 13.7713 16.783 12.2495 18.4225 12.0336C19.2973 11.9182 20.1046 12.0948 20.7846 12.6824C21.5599 13.352 21.8746 14.2245 21.7713 15.2425C21.6707 16.2326 21.1843 16.9853 20.3297 17.4822C19.8733 17.7491 19.3738 17.8646 18.8465 17.8549ZM18.857 16.9504C19.2623 16.9518 19.641 16.835 19.9542 16.6106C20.6568 16.1061 20.9487 15.1229 20.657 14.2428C20.1974 12.8547 18.4927 12.4432 17.5027 13.4898C17.0257 13.9936 16.8913 14.6129 16.9878 15.2908C17.1213 16.24 17.9183 16.9468 18.857 16.9504Z"
                    fill="#3D3935"
                />
                <path
                    d="M22.5276 12.9724V12.0999H27.0001C27.0001 12.3407 27.0028 12.581 26.9973 12.8209C26.9973 12.8556 26.9603 12.8923 26.9349 12.9232C25.8952 14.1959 24.855 15.4683 23.8142 16.7403C23.7834 16.778 23.7546 16.8172 23.7043 16.8821H26.9923V17.7542H22.4209C22.4209 17.5233 22.4172 17.2924 22.4243 17.0599C22.4257 17.0167 22.4668 16.971 22.4977 16.9331C23.5375 15.6604 24.578 14.3882 25.619 13.1165C25.6499 13.0786 25.6791 13.0396 25.7315 12.9724H22.5276Z"
                    fill="#3D3935"
                />
                <path
                    d="M11.983 17.8549C10.5131 17.8549 9.37729 16.9313 9.1142 15.469C8.80929 13.7713 9.91831 12.2504 11.5599 12.0336C12.4347 11.9182 13.242 12.0948 13.922 12.6824C14.6973 13.352 15.012 14.2245 14.9087 15.2425C14.8081 16.2326 14.3217 16.9853 13.4671 17.4822C13.0088 17.7491 12.5087 17.8646 11.983 17.8549ZM11.9933 16.9504C12.3985 16.9518 12.7775 16.835 13.0893 16.6106C13.7919 16.1061 14.0838 15.1229 13.7924 14.2428C13.3325 12.8547 11.6278 12.4432 10.6381 13.4898C10.1613 13.9936 10.0267 14.6129 10.1231 15.2908C10.2568 16.24 11.0539 16.9468 11.9926 16.9504H11.9933Z"
                    fill="#3D3935"
                />
                <path
                    d="M6.9168 15.9577V12.0998H7.85966V17.7624C7.60343 17.7624 7.34286 17.7656 7.08251 17.7585C7.04914 17.7585 7.01211 17.7065 6.9856 17.6719C6.01478 16.408 5.04434 15.1435 4.07429 13.8786C4.04483 13.8353 4.00441 13.8009 3.95726 13.7788V17.7555H3V12.0921C3.288 12.0921 3.57554 12.0894 3.86286 12.0958C3.89714 12.0958 3.93714 12.142 3.96297 12.176C4.90575 13.4038 5.8483 14.6323 6.79063 15.8615C6.82011 15.8996 6.85097 15.9365 6.88206 15.9741L6.9168 15.9577Z"
                    fill="#3F3A36"
                />
                <path
                    d="M18.8465 17.8549C17.3768 17.8549 16.2408 16.9313 15.9779 15.469C15.673 13.7713 16.783 12.2495 18.4225 12.0336C19.2973 11.9182 20.1046 12.0948 20.7846 12.6824C21.5599 13.352 21.8746 14.2245 21.7713 15.2425C21.6707 16.2326 21.1843 16.9853 20.3297 17.4822C19.8733 17.7491 19.3738 17.8646 18.8465 17.8549ZM18.857 16.9504C19.2623 16.9518 19.641 16.835 19.9542 16.6106C20.6568 16.1061 20.9487 15.1229 20.657 14.2428C20.1974 12.8547 18.4927 12.4432 17.5027 13.4898C17.0257 13.9936 16.8913 14.6129 16.9878 15.2908C17.1213 16.24 17.9183 16.9468 18.857 16.9504Z"
                    fill="#3F3A36"
                />
                <path
                    d="M22.5276 12.9724V12.0999H27.0001C27.0001 12.3407 27.0028 12.581 26.9973 12.8209C26.9973 12.8556 26.9603 12.8923 26.9349 12.9232C25.8952 14.1959 24.855 15.4683 23.8142 16.7403C23.7834 16.778 23.7546 16.8172 23.7043 16.8821H26.9923V17.7542H22.4209C22.4209 17.5233 22.4172 17.2924 22.4243 17.0599C22.4257 17.0167 22.4668 16.971 22.4977 16.9331C23.5375 15.6604 24.578 14.3882 25.619 13.1165C25.6499 13.0786 25.6791 13.0396 25.7315 12.9724H22.5276Z"
                    fill="#3F3A36"
                />
                <path
                    d="M11.983 17.8549C10.5131 17.8549 9.37729 16.9313 9.1142 15.469C8.80929 13.7713 9.91831 12.2504 11.5599 12.0336C12.4347 11.9182 13.242 12.0948 13.922 12.6824C14.6973 13.352 15.012 14.2245 14.9087 15.2425C14.8081 16.2326 14.3217 16.9853 13.4671 17.4822C13.0088 17.7491 12.5087 17.8646 11.983 17.8549ZM11.9933 16.9504C12.3985 16.9518 12.7775 16.835 13.0893 16.6106C13.7919 16.1061 14.0838 15.1229 13.7924 14.2428C13.3325 12.8547 11.6278 12.4432 10.6381 13.4898C10.1613 13.9936 10.0267 14.6129 10.1231 15.2908C10.2568 16.24 11.0539 16.9468 11.9926 16.9504H11.9933Z"
                    fill="#3F3A36"
                />
            </g>
            <defs>
                <clipPath id="clip0_18_13">
                    <rect width="24" height="5.85542" fill="white" transform="translate(3 12)" />
                </clipPath>
            </defs>
        </svg>
    );
};
