import { ProactiveSupportMessage } from "../modules/proactiveSupport/proactiveSupport.entities";

const KEY_PROACTIVE_SUPPORT = "KEY_PROACTIVE_SUPPORT";
const KEY_CURRENT_MESSAGE_ID = "KEY_CURRENT_MESSAGE_ID";
const KEY_CURRENT_TEXT_VERSION_ID = "KEY_CURRENT_TEXT_VERSION_ID";
const ID_NUMBER_SUPPORT = "ID_NUMBER_SUPPORT";

export const saveProactiveSupportMessages = (messages: ProactiveSupportMessage[]) => {
    window.sessionStorage.setItem(KEY_PROACTIVE_SUPPORT, JSON.stringify(messages));
};

export const getProactiveSupportMessages = (): ProactiveSupportMessage[] | null => {
    const cachedData = window.sessionStorage.getItem(KEY_PROACTIVE_SUPPORT);

    if (cachedData) {
        return JSON.parse(cachedData) as ProactiveSupportMessage[];
    }

    return null;
};

export const saveProactiveSupportCurrentIds = (currentMessageId: string, currentTextVersionId: string) => {
    window.localStorage.setItem(KEY_CURRENT_MESSAGE_ID, currentMessageId);
    window.localStorage.setItem(KEY_CURRENT_TEXT_VERSION_ID, currentTextVersionId);
};

export const getProactiveSupportCurrentIds = (): { currentMessageId: string; currentTextVersionId: string } => {
    const currentMessageId = window.localStorage.getItem(KEY_CURRENT_MESSAGE_ID) || "";
    const currentTextVersionId = window.localStorage.getItem(KEY_CURRENT_TEXT_VERSION_ID) || "";

    return { currentMessageId, currentTextVersionId };
};

export const getIdNumber = (): number => {
    const idNumber = window.localStorage.getItem(ID_NUMBER_SUPPORT);

    if (idNumber) return parseFloat(idNumber);

    const newIdNumber = Math.floor(Math.random() * 10);
    window.localStorage.setItem(ID_NUMBER_SUPPORT, newIdNumber.toString());

    return newIdNumber;
};
