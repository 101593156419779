/* eslint-disable camelcase */
import { Message } from "@twilio/conversations";

import { ClosedChat } from "./chatHistory.entities";
import { FlexConversation, FlexMessage } from "./chatHistory.entities.api";

export const toClosedChat = (conversation: FlexConversation): ClosedChat => {
    return { conversation, messages: [] };
};

export const toMessage = (message: FlexMessage): Message => {
    return {
        sid: message.index,
        author: message.author,
        body: message.body,
        dateCreated: new Date(message.date_created),
        dateUpdated: new Date(message.date_updated),
        index: message.index
    } as unknown as Message;
};
