import { ProductMessagingIcon } from "@twilio-paste/icons/esm/ProductMessagingIcon";

import { useTranslation } from "../hooks/useTranslation";

export default () => {
    const { i18n } = useTranslation();
    return (
        <>
            <div style={{ opacity: 0.7 }}>
                <ProductMessagingIcon color="colorText" decorative={true} size="sizeIcon100" />
            </div>
            <h4 style={{ marginTop: 20, marginBottom: 10 }}>{i18n.chatHistoryNoMessage}</h4>
            <div>{i18n.chatHistoryComingSoon}</div>
        </>
    );
};
