import { getIdNumber } from "../../../utils/storageProactiveSupport";
import { UseCaseBase } from "../../../utils/UseCaseBase";
import { ProactiveSupportTextVersion } from "../proactiveSupport.entities";
import { saveTextVersionStat } from "../proactiveSupport.repository";

type GetContentMessageProactiveSupportArguments = {
    messageId: string;
    textVersions: ProactiveSupportTextVersion[];
};

export class GetContentMessageProactiveSupportUseCase extends UseCaseBase<
    GetContentMessageProactiveSupportArguments,
    ProactiveSupportTextVersion | null
> {
    execute(params: GetContentMessageProactiveSupportArguments): ProactiveSupportTextVersion | null {
        const { messageId, textVersions } = params;

        const textVersionsLive = textVersions.filter((textVersion) => textVersion.state === "Live");

        if (textVersionsLive.length === 0) {
            return null;
        }

        const textVersion = this.getRandTextVersion(textVersionsLive);

        saveTextVersionStat(messageId, textVersion.uuid, "sent_count");

        return textVersion;
    }

    getRandTextVersion(textVersionsLive: ProactiveSupportTextVersion[]): ProactiveSupportTextVersion {
        if (textVersionsLive.length === 1) return textVersionsLive[0];

        const idNumber = getIdNumber();

        if (idNumber < 5) return textVersionsLive[0];

        return textVersionsLive[1];
    }
}
