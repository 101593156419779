import { UseCaseBase } from "../../../utils/UseCaseBase";
import { saveTextVersionStat } from "../proactiveSupport.repository";

type UserAnswersProactiveSupportArguments = {
    messageId: string;
    textVersionId: string;
};

export class UserAnswersProactiveSupportUseCase extends UseCaseBase<UserAnswersProactiveSupportArguments, void> {
    async execute(params: UserAnswersProactiveSupportArguments): Promise<void> {
        saveTextVersionStat(params.messageId, params.textVersionId, "answered_count");
    }
}
