import { ErrorHandler } from "./ErrorHandler";

export abstract class UseCaseBase<Input, Output> extends ErrorHandler {
    async run(params: Input) {
        try {
            const result = this.execute(params);

            if (result instanceof Promise) {
                const data = await result;
                return {
                    error: null,
                    data
                };
            }
            return {
                error: null,
                data: result
            };
        } catch (error) {
            return this.handleError(error);
        }
    }

    abstract execute(params: Input): Output | Promise<Output>;
}
