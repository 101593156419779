import { Box, Button, ModalBody, Modal as ModalFlex, ModalFooter, ModalFooterActions } from "@twilio-paste/core";
import { useDispatch, useSelector } from "react-redux";
import { ReactNode, useEffect, useState } from "react";
import { DownloadIcon } from "@twilio-paste/icons/esm/DownloadIcon";
import { Media } from "@twilio/conversations";

import { useDevice } from "../hooks/useDevice";
import { changeExpandedStatus, previewMedia } from "../store/actions/genericActions";
import { innerContainerStyles } from "./styles/RootContainer.styles";
import { AppState } from "../store/definitions";
import { forceDownload } from "./FilePreview";
import Drawer from "./Drawer";

export const WrapperRoot = ({ children }: { children: ReactNode }) => {
    const dispatch = useDispatch();
    const { previewMediaFile } = useSelector(({ chat }: AppState) => ({
        previewMediaFile: chat.previewMedia
    }));
    const { isMobile } = useDevice();

    const ModalPreview = ({ media }: { media: Media | undefined }) => {
        const [url, setUrl] = useState<string | undefined>(undefined);

        useEffect(() => {
            /*
             * TODO: Remove after client adds this to Web Client
             * https://privatebin.zebrands.mx/?46695e2cfaefc0a0#2o4V6dNDcMdBPj3YcQRSMHFsGCGKFn6agjqQQeivUB7P
             */
            setTimeout(() => {
                const element = document.querySelector("[data-reach-dialog-overlay]");
                if (element) {
                    const currentStyle = element.getAttribute("style") || "";
                    element.setAttribute("style", `${currentStyle} z-index: 1000;`);
                }
            }, 100);
        }, [media, media?.filename]);
        useEffect(() => {
            if (media) media.getContentTemporaryUrl().then((temporaryUrl) => setUrl(temporaryUrl));
            else setUrl(undefined);
        }, [media, media?.filename]);
        return (
            <ModalFlex
                ariaLabelledby="previewMedia"
                // eslint-disable-next-line eqeqeq
                isOpen={media != undefined}
                onDismiss={() => dispatch(previewMedia(undefined))}
                size="default"
            >
                <ModalBody>
                    <img style={{ maxWidth: "100%" }} src={url} alt="" />
                </ModalBody>
                <ModalFooter>
                    <ModalFooterActions>
                        <Button
                            variant="primary"
                            onClick={async () => {
                                if (media) {
                                    const temporaryUrl = await media.getContentTemporaryUrl();
                                    forceDownload(temporaryUrl, media.filename);
                                }
                            }}
                        >
                            <DownloadIcon decorative={false} title="Download" />
                        </Button>
                    </ModalFooterActions>
                </ModalFooter>
            </ModalFlex>
        );
    };

    if (isMobile) {
        return (
            <>
                <Drawer open={true} onClose={() => dispatch(changeExpandedStatus({ expanded: false }))}>
                    <Box
                        className="container-twilio-chat--modal"
                        height="100%"
                        style={{ display: "flex", flexDirection: "column" }}
                    >
                        {children}
                    </Box>
                </Drawer>
                <ModalPreview media={previewMediaFile} />
            </>
        );
    }

    return (
        <>
            <Box data-test="root-container" className="container-twilio-chat" {...innerContainerStyles}>
                {children}
            </Box>
            <ModalPreview media={previewMediaFile} />
        </>
    );
};
