import { Brand } from "../../definitions";
import {
    MatchType,
    Operator,
    ProactiveSupportMessage,
    ProactiveSupportRule,
    ProactiveSupportRuleSet,
    ProactiveSupportTextVersion,
    TextVersionState
} from "./proactiveSupport.entities";
import {
    InfraProactiveSupportMessage,
    InfraProactiveSupportRule,
    InfraProactiveSupportRuleSet,
    InfraProactiveSupportTextVersion
} from "./proactiveSupport.entities.api";

const toProactiveSupportRule = (infraRule: InfraProactiveSupportRule): ProactiveSupportRule => {
    return {
        uuid: infraRule.uuid,
        isAffirmative: infraRule.is_affirmative,
        matchType: infraRule.match_type as MatchType,
        query: infraRule.query
    };
};

const toProactiveSupportRuleSet = (infraRuleSet: InfraProactiveSupportRuleSet): ProactiveSupportRuleSet => {
    return {
        uuid: infraRuleSet.uuid,
        operator: infraRuleSet.operator as Operator,
        rules: infraRuleSet.rules.map(toProactiveSupportRule)
    };
};

const toProactiveSupportTextVersion = (
    infraTextVersion: InfraProactiveSupportTextVersion
): ProactiveSupportTextVersion => {
    return {
        uuid: infraTextVersion.uuid,
        text: infraTextVersion.text,
        sentCount: infraTextVersion.sent_count,
        openedCount: infraTextVersion.opened_count,
        answeredCount: infraTextVersion.answered_count,
        state: infraTextVersion.state as TextVersionState
    };
};

export const toProactiveSupportMessage = (infraMessage: InfraProactiveSupportMessage): ProactiveSupportMessage => {
    return {
        uuid: infraMessage.uuid,
        createdAt: infraMessage.created_at,
        updatedAt: infraMessage.updated_at,
        title: infraMessage.title,
        active: infraMessage.active,
        brand: infraMessage.brand as Brand,
        eventTrigger: infraMessage.event_trigger,
        timesToShow: infraMessage.times_to_show,
        startSending: infraMessage.start_sending,
        stopSending: infraMessage.stop_sending,
        ruleSetOperator: infraMessage.ruleset_operator as Operator,
        sentCount: infraMessage.sent_count,
        ruleSets: infraMessage.rulesets.map(toProactiveSupportRuleSet),
        textVersions: infraMessage.text_versions.map(toProactiveSupportTextVersion)
    };
};
