// eslint-disable-next-line no-use-before-define
import React from "react";
import { ChevronRightIcon } from "@twilio-paste/icons/esm/ChevronRightIcon";
import { Box, Separator } from "@twilio-paste/core";
// eslint-disable-next-line import/no-extraneous-dependencies
import dayjs from "dayjs";
// eslint-disable-next-line import/no-extraneous-dependencies
import relativeTime from "dayjs/plugin/relativeTime";
import { Participant } from "@twilio/conversations";
import { UserIcon } from "@twilio-paste/icons/esm/UserIcon";

import { getAvatarContainerStyles } from "./styles/MessageBubble.styles";

// eslint-disable-next-line import/no-extraneous-dependencies, @typescript-eslint/no-require-imports
require("dayjs/locale/es");

const ConversationItem = ({
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    participants,
    lastMessage,
    updatedAt,
    onClick
}: {
    participants: Participant[];
    lastMessage?: string;
    updatedAt?: string;
    onClick?: () => void;
}) => {
    dayjs.locale("es");
    dayjs.extend(relativeTime);
    return (
        <>
            <Box
                onClick={onClick}
                style={{
                    height: 60,
                    display: "flex",
                    flex: 1,
                    alignSelf: "stretch",
                    flexDirection: "row",
                    alignItems: "center",
                    padding: 20,
                    cursor: "pointer"
                }}
            >
                <Box {...getAvatarContainerStyles(false)} data-testid="avatar-container">
                    <UserIcon decorative={true} size="sizeIcon40" />
                </Box>
                <div style={{ flex: 1, flexDirection: "column", flexGrow: 1 }}>
                    <div
                        style={{
                            width: 250,
                            overflow: "hidden",
                            whiteSpace: "nowrap",
                            textOverflow: "ellipsis",
                            fontSize: 14,
                            fontWeight: "400"
                        }}
                    >
                        {lastMessage || "loading"}
                    </div>
                    <div style={{ fontSize: 12 }}>{dayjs(updatedAt).fromNow()}</div>
                </div>
                <ChevronRightIcon color="colorText" decorative={false} size="sizeIcon40" title="close" />
            </Box>
            <Separator orientation="horizontal" verticalSpacing="space0" />
        </>
    );
};

export default ConversationItem;
