import { triggerGtmEvent } from "../../../utils/gtm";

type ResponseUseEvents = {
    onMessageShown: (messageId: string) => void;
    onMessageClicked: (messageId: string) => void;
    onSendReply: (messageId: string | null) => void;
    onChatStarted: (messageId: string | null) => void;
};

export const useEvents = (): ResponseUseEvents => {
    const onMessageShown = (messageId: string) => {
        triggerGtmEvent({ eventName: "Triggered Message", messageId, interactive: false });
    };

    const onMessageClicked = (messageId: string) => {
        triggerGtmEvent({ eventName: "Viewed Message", messageId, interactive: false });
    };

    const onSendReply = (messageId: string | null) => {
        triggerGtmEvent({ eventName: "Sent a Reply", messageId });
    };

    const onChatStarted = (messageId: string | null) => {
        triggerGtmEvent({ eventName: "Started New Conversation", messageId });
    };

    return {
        onMessageShown,
        onMessageClicked,
        onSendReply,
        onChatStarted
    };
};
