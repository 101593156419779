import { useEffect, useRef, useState, KeyboardEvent } from "react";
import { Box, Button, TextArea } from "@twilio-paste/core";
import { SendIcon } from "@twilio-paste/icons/esm/SendIcon";
import { useDispatch, useSelector } from "react-redux";

import { AttachFileDropArea } from "./AttachFileDropArea";
import { Header } from "./Header";
import { NotificationBar } from "./NotificationBar";
import {
    formStyles,
    innerInputStyles,
    messageOptionContainerStyles,
    textAreaContainerStyles
} from "./styles/MessageInput.styles";
import { useTranslation } from "../hooks/useTranslation";
import { CHAR_LIMIT, PREFIX_PROACTIVE_SUPPORT } from "../constants";
import ProactiveSupportMessagePreview from "./ProactiveSupportMessagePreview";
import { AppState, EngagementPhase } from "../store/definitions";
import {
    addNotification,
    changeEngagementPhase,
    deleteByIdProactiveSupportMessages
} from "../store/actions/genericActions";
import { sessionDataHandler } from "../sessionDataHandler";
import { useNotifications } from "../hooks/useNotifications";
import { initSession } from "../store/actions/initActions";
import useCampaignInterceptor from "../hooks/useCampaignInterceptor";

export const PreviewProactiveSupport = () => {
    const { campaignDetail } = useCampaignInterceptor();
    const textAreaRef = useRef<HTMLTextAreaElement>(null);
    const [isSending, setIsSending] = useState(false);
    const dispatch = useDispatch();
    const notifications = useNotifications();

    const [text, setText] = useState("");

    const { messageId, textVersionId, messages, name, email, brand, posProfile } = useSelector((state: AppState) => ({
        messages: state?.proactiveSupport?.messages || [],
        messageId: state.proactiveSupport?.currentMessageId,
        textVersionId: state.proactiveSupport?.currentTextVersionId,
        name: state?.session?.preEngagementData?.name || "",
        email: state?.session?.preEngagementData?.email || "",
        brand: state.config.brand,
        posProfile: state.config.posProfile
    }));

    const { i18n } = useTranslation();

    const onTouchMove = () => textAreaRef.current?.blur();

    const onFocus = () => {
        document.addEventListener("touchmove", onTouchMove);
    };

    const onBlur = () => {
        document.removeEventListener("touchmove", onTouchMove);
    };

    const onChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
        setText(e.target.value);
    };

    const onKeyPress = (e: KeyboardEvent<HTMLTextAreaElement>) => {
        if (e.key === "Enter" && !e.shiftKey) {
            e.preventDefault();
            (e.target as HTMLInputElement).form?.dispatchEvent(
                new Event("submit", { cancelable: true, bubbles: true })
            );
        }
    };

    useEffect(() => {
        textAreaRef.current?.setAttribute("rows", "1");
    }, [textAreaRef]);

    const messageSelected = messages.find((message) => message.uuid === messageId);

    const contentSelect =
        messageSelected?.textVersions.find((textVersion) => textVersion.uuid === textVersionId)?.text || "";

    const sendMessageWhenChatIsClosed = async (): Promise<void> => {
        try {
            dispatch(changeEngagementPhase({ phase: EngagementPhase.Loading }));

            const query = `${contentSelect} ${PREFIX_PROACTIVE_SUPPORT}`;
            const data = await sessionDataHandler.fetchAndStoreNewSession({
                formData: {
                    friendlyName: name,
                    secondQuery: text,
                    email,
                    query,
                    brand,
                    posProfile,
                    utmCampaign: campaignDetail?.utmCampaign,
                    utmSource: campaignDetail?.utmSource
                }
            });

            dispatch(deleteByIdProactiveSupportMessages(messageId || ""));

            dispatch(
                initSession({
                    token: data.token,
                    conversationSid: data.conversationSid,
                    notifications,
                    messageId
                })
            );
        } catch (error) {
            dispatch(addNotification(notifications.failedToInitSessionNotification((error as Error).message)));
            dispatch(changeEngagementPhase({ phase: EngagementPhase.PreEngagementForm }));
        }
    };

    return (
        <AttachFileDropArea>
            <Header />
            <NotificationBar />

            <ProactiveSupportMessagePreview message={contentSelect} />

            <Box
                as="form"
                {...formStyles}
                onSubmit={(e) => {
                    e.preventDefault();
                    setIsSending(true);
                    sendMessageWhenChatIsClosed();
                }}
            >
                <Box as="div" {...innerInputStyles}>
                    <Box {...textAreaContainerStyles}>
                        <TextArea
                            ref={textAreaRef}
                            data-test="message-input-textarea"
                            placeholder={i18n.messagingInput}
                            value={text}
                            element="MESSAGE_INPUT"
                            onChange={onChange}
                            onFocus={onFocus}
                            onBlur={onBlur}
                            readOnly={isSending}
                            onKeyPress={onKeyPress}
                            maxLength={CHAR_LIMIT}
                        />
                    </Box>
                    <Box {...messageOptionContainerStyles}>
                        <Button data-test="message-send-button" variant="primary_icon" size="icon_small" type="submit">
                            <SendIcon decorative={false} title="Send message" size="sizeIcon40" />
                        </Button>
                    </Box>
                </Box>
            </Box>
        </AttachFileDropArea>
    );
};
