import { BoxStyleProps } from "@twilio-paste/core/box";

export const getContainerStyles = (isMobile: boolean): BoxStyleProps => {
    return {
        display: "flex",
        justifyContent: "space-around",
        alignItems: "center",
        backgroundColor: "colorBackgroundBody",
        borderBottomLeftRadius: "borderRadius30",
        borderBottomRightRadius: "borderRadius30",
        ...(isMobile && {
            borderRadius: "borderRadius0"
        })
    };
};

export const getSectionStyles = (selected: boolean): BoxStyleProps => {
    return {
        width: "50%",
        backgroundColor: selected ? "colorBackgroundBrandStronger" : "colorBackgroundBody",
        opacity: 0.75,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        padding: "space20"
    };
};
