export class ErrorHandler {
    public handleError(error: unknown): {
        data: null;
        error: string;
    } {
        let message = "An unexpected error occurred";

        if (typeof error === "string") {
            message = error;
        }

        return {
            data: null,
            error: message || "An unexpected error occurred"
        };
    }
}
