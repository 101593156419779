import { sessionDataHandler } from "../sessionDataHandler";
import {
    changeEngagementPhase,
    changeExpandedStatus,
    updateMessageInput,
    updatePreEngagementData
} from "../store/actions/genericActions";
import { EngagementPhase } from "../store/definitions";
import { store } from "../store/store";
import { clearIdentity } from "./identityHandlers";

export class ChatDispatcher {
    /**
     *  Expands the chat window
     */
    public openChatWindow() {
        // Open chat window
        store.dispatch(changeExpandedStatus({ expanded: true }));
    }

    /**
     * Closes the chat window
     */
    public closeChatWindow() {
        // Close chat window
        store.dispatch(changeExpandedStatus({ expanded: false }));
    }

    /**
     * Opens the chat window and prefills the message input with the provided message
     *
     * @remarks
     * This would not send a message to the agent, it merely pre-fills the message input.
     *
     * @param message - The message to prefill the message input with
     */
    public showNewMessage(message: string) {
        const state = store.getState();

        if (state.session?.currentPhase === "PreEngagementForm") {
            store.dispatch(updatePreEngagementData({ query: message }));
        } else {
            store.dispatch(updateMessageInput(message));
        }
        this.openChatWindow();
    }

    /**
     * The callback is called when the chat window is shown
     *
     * @param callback - The callback to be called when the chat window is shown. It does not receive any arguments.
     */
    public onShow(callback: () => void) {
        let previousValue: boolean | undefined;

        store.subscribe(() => {
            const state = store.getState();
            const currentValue = state.session.expanded;
            const valueChanged = previousValue !== undefined && previousValue !== currentValue;
            previousValue = currentValue;

            if (!currentValue || !valueChanged) {
                return;
            }

            callback();
        });
    }

    /**
     * The callback is called when the chat window is hidden
     *
     * @param callback - The callback to be called when the chat window is hidden. It does not receive any arguments.
     */
    public onHide(callback: () => void) {
        let previousValue: boolean | undefined;

        store.subscribe(() => {
            const state = store.getState();
            const currentValue = state.session.expanded;
            const valueChanged = previousValue !== undefined && previousValue !== currentValue;
            previousValue = currentValue;
            if (currentValue || !valueChanged) {
                return;
            }

            callback();
        });
    }

    /**
     * Whenever the amount of unread messages changes, the callback is called with the new count
     *
     * @remarks
     * The amount of messages will change when the user reads a message, or when the agent responds
     * to the user but the chat window is minimized
     *
     * @param callback - The callback to be called when the amount of unread messages changes. It receives the new count as an argument.
     */
    public onUnreadCountChange(callback: (newCount: number) => void) {
        let prevUnread = 0;
        store.subscribe(() => {
            const state = store.getState();
            state.chat?.conversation?.getUnreadMessagesCount().then((unreadCount) => {
                if (prevUnread !== unreadCount) {
                    // eslint-disable-next-line callback-return
                    callback(unreadCount || 0);
                }
                prevUnread = unreadCount || 0;
            });
        });
    }

    /**
     * Resets the session, clearing all data and returning to the Pre Engagement phase
     *
     * @remarks
     * This effectively closes the current conversation (if exists) and prepares the widget
     * to start a new one.
     */
    public resetSession() {
        sessionDataHandler.clear();
        clearIdentity();
        store.dispatch(updatePreEngagementData({ email: "", name: "", query: "" }));
        store.dispatch(changeEngagementPhase({ phase: EngagementPhase.PreEngagementForm }));
    }
}
