import { GetMessageProactiveSupportArguments, ProactiveSupportMessage } from "../proactiveSupport.entities";
import { getProactiveSupportMessagesRepo } from "../proactiveSupport.repository";
import { UseCaseBase } from "../../../utils/UseCaseBase";

export class GetProactiveSupportMessagesUseCase extends UseCaseBase<
    GetMessageProactiveSupportArguments,
    ProactiveSupportMessage[]
> {
    async execute(params: GetMessageProactiveSupportArguments): Promise<ProactiveSupportMessage[]> {
        return getProactiveSupportMessagesRepo(params);
    }
}
