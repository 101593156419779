import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import { GetProactiveSupportMessagesUseCase } from "../modules/proactiveSupport/useCases/getProactiveSupportMessages.useCase";
import { AppState } from "../store/definitions";
import { updateCurrentEventTrigger, updateProactiveSupportMessages } from "../store/actions/genericActions";
import { getProactiveSupportCurrentIds } from "../utils/storageProactiveSupport";

export const useProactiveSupportMessage = () => {
    const dispatch = useDispatch();
    const brand = useSelector((state: AppState) => state.config.brand);

    useEffect(() => {
        const initMessages = async () => {
            if (!brand) return;

            const getProactiveSupportMessagesUseCase = new GetProactiveSupportMessagesUseCase();

            const { data } = await getProactiveSupportMessagesUseCase.run({
                brand
            });

            if (data === null) return;

            const { currentMessageId, currentTextVersionId } = getProactiveSupportCurrentIds();
            dispatch(updateProactiveSupportMessages(data, currentMessageId, currentTextVersionId));

            if (window?.analytics?.on)
                window?.analytics?.on("track", (event) => {
                    const existedEvent = data.find((message) => message.eventTrigger === event);
                    if (existedEvent) {
                        dispatch(updateCurrentEventTrigger(existedEvent.eventTrigger));
                    }
                });
        };

        initMessages();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
};
