import { Box } from "@twilio-paste/core";
import { useSelector } from "react-redux";

import { AppState } from "../store/definitions";
import { Brand } from "../definitions";
import { Luuna } from "./icons/Luuna";
import { Nooz } from "./icons/Nooz";
import { Mappa } from "./icons/Mappa";

const ProactiveSupportAvatars = () => {
    const brand = useSelector((state: AppState) => state.config.brand);

    const dictionaryIcon: {
        [key in Brand]: JSX.Element;
    } = {
        LUUNA: <Luuna />,
        NOOZ: <Nooz />,
        MAPPA: <Mappa />,
        "Luuna Brasil": <Luuna />
    };

    return <Box display="flex">{dictionaryIcon[brand || "LUUNA"]}</Box>;
};

export default ProactiveSupportAvatars;
