import { Client } from "@twilio/conversations";

import { ConversationMessagesResponse, ConversationsMeResponse } from "./chatHistory.entities.api";
// import { getCurrentUserIdentity } from "../../utils/identityHandlers";
import { toClosedChat, toMessage } from "./chatHistory.adapters";
import { ClosedChat } from "./chatHistory.entities";
import { MESSAGES_LOAD_COUNT } from "../../constants";
import { getCurrentUserIdentity } from "../../utils/identityHandlers";

const contentType = "application/json";

export const getChatHistoryRepo = async (
    userIdentity: string,
    conversationsClient: Client | undefined
): Promise<ClosedChat[]> => {
    const identity = getCurrentUserIdentity();
    const response = await fetch(
        `${process.env.CONTACT_CENTER_API}/api/v1/conversations/search_by_address?address=${identity.flex}`,
        {
            method: "GET",
            headers: {
                Accept: contentType,
                "Content-Type": contentType
            }
        }
    );

    if (!response.ok) {
        throw new Error("Request to backend failed");
    }

    const data = await response.json();
    const conversationsResponse = data as ConversationsMeResponse;

    const closedChats = (
        conversationsResponse.conversations?.filter(
            (conversation) => conversation.status === "closed" && conversation.type === "web"
        ) || []
    ).map(toClosedChat);

    try {
        if (!conversationsClient) throw new Error("Conversations client not found");
        for (const closedChat of closedChats) {
            const conversation = await conversationsClient.getConversationBySid(closedChat.conversation.sid);

            const participants = await conversation.getParticipants();
            closedChat.participants = participants;
            const users = await Promise.all(participants.map(async (p) => p.getUser()));
            closedChat.users = users;
            const messages = (await conversation.getMessages(MESSAGES_LOAD_COUNT)).items;
            closedChat.messages = messages;
        }
    } catch (e) {
        for (const closedChat of closedChats) {
            const responseMessages = await fetch(
                `${process.env.CONTACT_CENTER_API}/api/v1/conversations/${closedChat.conversation.sid}`,
                {
                    method: "GET",
                    headers: {
                        Accept: contentType,
                        "Content-Type": contentType
                    }
                }
            );
            const { messages } = (await responseMessages.json()) as ConversationMessagesResponse;
            closedChat.messages = messages.map(toMessage);
        }
    }

    return closedChats;
};
