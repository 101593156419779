const LOCAL_STORAGE_IDENTITY_KEY = "TWILIO_WEBCHAT_IDENTITY";

interface SegmentIdentity {
    userId: string | null | undefined;
    anonymousId: string;
}

interface Identity {
    flex: string | null;
    segment: SegmentIdentity;
}

export function storeIdentity(identity: string) {
    if (localStorage.getItem(LOCAL_STORAGE_IDENTITY_KEY)) {
        // Only store the identity once
        return;
    }
    localStorage.setItem(LOCAL_STORAGE_IDENTITY_KEY, identity);
}

export function clearIdentity(): void {
    localStorage.removeItem(LOCAL_STORAGE_IDENTITY_KEY);
}

export function getCurrentUserIdentity(): Identity {
    const identity = {
        flex: null,
        segment: {} as SegmentIdentity
    } as Identity;
    const flex = localStorage.getItem(LOCAL_STORAGE_IDENTITY_KEY);

    if (flex) {
        identity.flex = flex;
    }
    const segment: SegmentIdentity = {
        userId: window?.analytics?.user()?.id(),
        anonymousId: window?.analytics?.user()?.anonymousId()
    };

    if (segment) {
        identity.segment = segment;
    }

    return identity;
}

declare global {
    interface Window {
        analytics?: SegmentAnalytics.AnalyticsJS;
    }
}
