// Config reducer actions
export const ACTION_LOAD_CONFIG = "ACTION_LOAD_CONFIG";

// Chat reducer actions
export const ACTION_CHANGE_EXPANDED_STATUS = "ACTION_CHANGE_EXPANDED_STATUS";
export const ACTION_CHANGE_ENGAGEMENT_PHASE = "ACTION_CHANGE_ENGAGEMENT_PHASE";
export const ACTION_START_SESSION = "ACTION_START_SESSION";
export const ACTION_UPDATE_PARTICIPANT = "ACTION_UPDATE_PARTICIPANT";
export const ACTION_ADD_PARTICIPANT = "ACTION_ADD_PARTICIPANT";
export const ACTION_REMOVE_PARTICIPANT = "ACTION_REMOVE_PARTICIPANT";
export const ACTION_UPDATE_MESSAGE = "ACTION_UPDATE_MESSAGE";
export const ACTION_ADD_MESSAGE = "ACTION_ADD_MESSAGE";
export const ACTION_REMOVE_MESSAGE = "ACTION_REMOVE_MESSAGE";
export const ACTION_ADD_MULTIPLE_MESSAGES = "ACTION_ADD_MULTIPLE_MESSAGES";
export const ACTION_ATTACH_FILES = "ACTION_ATTACH_FILES";
export const ACTION_DETACH_FILES = "ACTION_DETACH_FILES";
export const ACTION_PREVIEW_MEDIA = "ACTION_PREVIEW_MEDIA";
export const ACTION_UPDATE_CONVERSATION_STATE = "ACTION_UPDATE_CONVERSATION_STATE";
export const ACTION_UPDATE_SESSION_DATA = "ACTION_UPDATE_SESSION_DATA";
export const ACTION_UPDATE_PRE_ENGAGEMENT_DATA = "ACTION_UPDATE_PRE_ENGAGEMENT_DATA";
export const ACTION_UPDATE_MESSAGE_INPUT = "ACTION_UPDATE_MESSAGE_INPUT";
export const ACTION_ADD_CLOSED_CONVERSATIONS = "ACTION_ADD_CLOSED_CONVERSATIONS";
export const ACTION_SELECT_CHAT_ROUTE = "ACTION_SELECT_CHAT_ROUTE";
export const ACTION_SELECT_CONVERSATION = "ACTION_SELECT_CONVERSATION";

// Notification reducer actions
export const ACTION_ADD_NOTIFICATION = "ACTION_ADD_NOTIFICATION";
export const ACTION_REMOVE_NOTIFICATION = "ACTION_REMOVE_NOTIFICATION";

// ProactiveSupport reducer actions
export const ACTION_UPDATE_PROACTIVE_SUPPORT_MESSAGES = "ACTION_UPDATE_PROACTIVE_SUPPORT_MESSAGES";
export const ACTION_DELETE_PROACTIVE_SUPPORT_MESSAGE = "ACTION_DELETE_PROACTIVE_SUPPORT_MESSAGE";
export const ACTION_UPDATE_CURRENT_EVENT_TRIGGER = "ACTION_UPDATE_CURRENT_EVENT_TRIGGER";
export const ACTION_DELETE_ALL_PROACTIVE_SUPPORT_MESSAGES = "ACTION_DELETE_ALL_PROACTIVE_SUPPORT_MESSAGES";

export const ACTION_OPEN_MESSAGE_PROACTIVE_SUPPORT = "ACTION_OPEN_MESSAGE_PROACTIVE_SUPPORT";
export const ACTION_ANSWER_PROACTIVE_SUPPORT = "ACTION_ANSWER_PROACTIVE_SUPPORT";
export const ACTION_SET_CHAT_PROACTIVE_MESSAGE_LINK = "ACTION_SET_CHAT_PROACTIVE_MESSAGE_LINK";
