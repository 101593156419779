import { ChatIcon } from "@twilio-paste/icons/esm/ChatIcon";
import { CalendarIcon } from "@twilio-paste/icons/esm/CalendarIcon";
import { Box } from "@twilio-paste/core/box";
import { Button, Separator } from "@twilio-paste/core";
import { Dispatch } from "redux";
import { useDispatch, useSelector } from "react-redux";

import { getContainerStyles, getSectionStyles } from "./styles/Footer.styles";
import { useDevice } from "../hooks/useDevice";
import { ChatActions } from "../store/chat.reducer";
import { AppState } from "../store/definitions";

export const Footer = () => {
    const dispatch = useDispatch<Dispatch<ChatActions>>();
    const { isMobile } = useDevice();
    const chatRoute = useSelector((state: AppState) => state.chat.chatRoute);

    return (
        <>
            <Separator orientation="horizontal" verticalSpacing="space0" />
            <Box as="footer" {...getContainerStyles(isMobile)} overflow="hidden">
                <Box {...getSectionStyles(chatRoute === "active")}>
                    <Button
                        variant="primary_icon"
                        size="icon"
                        onClick={() => {
                            dispatch({ type: "ACTION_SELECT_CHAT_ROUTE", payload: "active" });
                        }}
                    >
                        <ChatIcon
                            color={chatRoute === "active" ? "colorTextBrandInverse" : "colorText"}
                            decorative={false}
                            size="sizeIcon40"
                            title="close"
                        />
                    </Button>
                </Box>
                <Box {...getSectionStyles(chatRoute === "closed")}>
                    <Button
                        variant="secondary_icon"
                        size="icon"
                        onClick={() => {
                            dispatch({ type: "ACTION_SELECT_CHAT_ROUTE", payload: "closed" });
                        }}
                    >
                        <CalendarIcon
                            color={chatRoute === "closed" ? "colorTextBrandInverse" : "colorText"}
                            decorative={false}
                            size="sizeIcon40"
                            title="close"
                        />
                    </Button>
                </Box>
            </Box>
        </>
    );
};
