/* eslint-disable @typescript-eslint/member-ordering */
import {
    Operator,
    ProactiveSupportRule,
    ProactiveSupportRuleSet
} from "../modules/proactiveSupport/proactiveSupport.entities";

export enum MatchType {
    URL_EXACT = "URL_EXACT",
    URL_STARTS_WITH = "URL_STARTS_WITH",
    URL_ENDS_WITH = "URL_ENDS_WITH",
    URL_CONTAINS = "URL_CONTAINS"
}

export class UrlValidator {
    protected mainOperator: Operator;

    protected rulesets: ProactiveSupportRuleSet[];

    constructor(operator: Operator, rulesets: ProactiveSupportRuleSet[]) {
        this.mainOperator = operator;
        this.rulesets = rulesets;
    }

    match(url: string): boolean {
        if (this.mainOperator === "and") {
            return this.rulesets.every((ruleset) => this.matchRuleset(url, ruleset));
        }

        return this.rulesets.some((ruleset) => this.matchRuleset(url, ruleset));
    }

    matchRuleset(url: string, ruleset: ProactiveSupportRuleSet): boolean {
        if (ruleset.operator === "and") {
            return ruleset.rules.every((rule) => this.matchRule(url, rule));
        }

        return ruleset.rules.some((rule) => this.matchRule(url, rule));
    }

    matchRule(url: string, rule: ProactiveSupportRule): boolean {
        if (rule.isAffirmative) {
            return this.testPattern(url, rule);
        }

        return !this.testPattern(url, rule);
    }

    testPattern(url: string, rule: ProactiveSupportRule): boolean {
        switch (rule.matchType) {
            case MatchType.URL_EXACT:
                return url === rule.query;
            case MatchType.URL_STARTS_WITH:
                return url.startsWith(rule.query);
            case MatchType.URL_ENDS_WITH:
                return url.endsWith(rule.query);
            case MatchType.URL_CONTAINS:
                return url.includes(rule.query);
            default:
                return false;
        }
    }
}
